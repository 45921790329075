// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/disjoint-force-directed-graph
class ForceGraph {
  constructor(
    {
      nodes, // an iterable of node objects (typically [{id}, …])
      links, // an iterable of link objects (typically [{source, target}, …])
    },
    {
      nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
      nodeGroup, // given d in nodes, returns an (ordinal) value for color
      nodeGroups, // an array of ordinal values representing the node groups
      nodeTitle, // given d in nodes, a title string
      nodeRank, // given d in nodes, a title string
      nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
      nodeStroke = "#fff", // node stroke color
      nodeStrokeWidth = 1.5, // node stroke width, in pixels
      nodeStrokeOpacity = 1, // node stroke opacity
      nodeRadius = 5, // node radius, in pixels
      nodeSelect,
      nodeImage,
      nodeStrength,
      linkSource = ({ source }) => source, // given d in links, returns a node identifier string
      linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
      linkStroke = "#999", // link stroke color
      linkStrokeOpacity = 0.6, // link stroke opacity
      linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
      linkStrokeLinecap = "round", // link stroke linecap
      linkStrength,
      width = 640, // outer width, in pixels
      height = 400, // outer height, in pixels
      profileSize = 20,
      invalidation, // when this promise resolves, stop the simulation
    } = {}
  ) {
    nodes = nodes.sort((a, b) => {
      if (a.rank == "user") {
        return -1;
      }
      return b.rank - a.rank;
    });
    // Compute values.
    const N = d3.map(nodes, nodeId).map(intern);
    const LS = d3.map(links, linkSource).map(intern);
    const LT = d3.map(links, linkTarget).map(intern);
    if (nodeTitle === undefined) nodeTitle = (_, i) => N[i];
    const T = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
    const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
    const R = nodeRank == null ? null : d3.map(nodes, nodeRank).map(intern);
    const I = nodeImage == null ? null : d3.map(nodes, nodeImage).map(intern);
    const W =
      typeof linkStrokeWidth !== "function"
        ? null
        : d3.map(links, linkStrokeWidth);

    const fontSizes = [32, 18, 14];
    const colors = ["#6F4BF9", "#FCB332", "#797b89"];

    let linkCount = {};

    links.forEach((link) => {
      if (linkCount[link.source]) {
        linkCount[link.source]++;
      } else {
        linkCount[link.source] = 1;
      }
    });

    this.profileSize = profileSize;

    this.width = width;
    this.height = height;
    this.svg = d3
      .create("svg")
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr(
        "style",
        "margin: auto; max-width: 100%; height: calc(100vh - 276px) ; height: intrinsic;"
      );
    nodes = d3.map(nodes, (_, i) => ({
      id: N[i],
      width: getWidth(i),
      height: fontSize(i) + 10,
      rankGroup: rankGroup(R[i]),
      title: T[i],
    }));
    links = d3.map(links, (_, i) => ({ source: LS[i], target: LT[i] }));

    // Compute default domains.
    if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);

    // Construct the forces
    const forceNode = d3
      .forceManyBody()
      .strength((d, i) => (i ? 0 : (-width * 1) / 2));
    const forceLink = d3.forceLink(links).id((d) => d.id);
    if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
    if (linkStrength !== undefined) forceLink.strength(0.01);

    const nodeIds = new Set(nodes.map((node) => node.id)); // 노드 id 집합 생성
    const missingLinks = links.filter(
      (link) => !nodeIds.has(link.source) || !nodeIds.has(link.target)
    );

    if (missingLinks.length > 0) {
      missingLinks.forEach((link) => {
        if (!nodeIds.has(link.source)) {
          nodes.push({ id: link.source, width: 0, height: 0 }); // 임시 노드 추가
        }
        if (!nodeIds.has(link.target)) {
          nodes.push({ id: link.target, width: 0, height: 0 }); // 임시 노드 추가
        }
      });
    }
    const validLinks = links.filter(
      (link) => nodeIds.has(link.source) && nodeIds.has(link.target)
    );

    forceLink.links(validLinks); // 유효한 링크만 설정
    const simulation = d3
      .forceSimulation(nodes)
      .force("link", forceLink)
      .force("x", d3.forceX().strength(0.1))
      .force("y", d3.forceY().strength(0.4))
      .force("charge", forceNode)
      .force(
        "collide",
        d3.forceCollide().radius((d) => d.height * 2)
      )
      .on("tick", this.ticked);

    this.link = this.svg
      .append("g")
      .attr("stroke", linkStroke)
      .attr("stroke-opacity", linkStrokeOpacity)
      .attr(
        "stroke-width",
        typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null
      )
      .attr("stroke-linecap", linkStrokeLinecap)
      .selectAll("line")
      .data(links)
      .join("line");

    if (W) this.link.attr("stroke-width", ({ index: i }) => W[i]);

    const node = this.svg
      .append("g")
      .selectAll("g")
      .data(nodes)
      .join("g")
      .call(drag(simulation));

    this.user = node
      .filter(({ index: i }) => G[i] == "user")
      .append("image")
      .attr("href", ({ index: i }) => I[i])
      .attr("width", this.profileSize)
      .attr("height", this.profileSize)
      .attr("title", ({ index: i }) => T[i])
      .attr("clip-path", "circle(" + this.profileSize / 2 + "px)")
      .style("object-fit", "cover");

    this.rect = node
      .filter(({ index: i }) => G[i] == "keyword")
      .append("rect")
      .attr("width", (d) => d.width)
      .attr("height", (d) => d.height)
      .attr("fill", ({ index: i }) => rankColor(i))
      .attr("rx", (d) => d.height / 2);

    this.text = node
      .filter(({ index: i }) => G[i] == "keyword")
      .append("text")
      .attr("fill", "white")
      .text(({ index: i }) => T[i])
      .attr("font-family", "Raleway", "Helvetica Neue, Helvetica")
      .attr("font-size", ({ index: i }) => {
        return fontSize(i) + "px";
      })
      .attr("text-anchor", "middle")
      .attr("font-weight", (d) => {
        if (d.height == 42) {
          return "bold";
        }
        return "";
      });

    // Handle invalidation.
    if (invalidation != null) invalidation.then(() => simulation.stop());

    function intern(value) {
      return value !== null && typeof value === "object"
        ? value.valueOf()
        : value;
    }

    function getWidth(i) {
      const re = /[\u3131-\uD79D]/giu;
      var nodewidth = fontSize(i) + T[i].length * fontSize(i) * 0.55;
      if (T[i].match(re) !== null) {
        nodewidth += T[i].match(re).length * fontSize(i) * 0.35;
      }
      return nodewidth;
    }
    function rankGroup(i) {
      const counts = Object.values(linkCount);
      const sortedCounts = counts.sort((a, b) => b - a);
      const firstGroupCount = sortedCounts[0];
      const secondGroupCount = sortedCounts.find(
        (count) => count < firstGroupCount
      );
      const thirdGroupCount = sortedCounts.find(
        (count) => count < secondGroupCount
      );

      if (linkCount[N[i]] === firstGroupCount) {
        return 0; // 첫 번째 그룹
      } else if (
        linkCount[N[i]] === secondGroupCount ||
        linkCount[N[i]] === thirdGroupCount
      ) {
        return 1; // 두 번째 그룹
      } else {
        return 2; // 세 번째 그룹
      }
    }
    function fontSize(i) {
      return fontSizes[rankGroup(i)];
    }
    function rankColor(i) {
      return colors[rankGroup(i)];
    }

    function drag(simulation) {
      function dragstarted(event) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }

      function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }

      function dragended(event) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }

    // return svg.node()
  }

  graph = () => {
    return this.svg.node();
  };

  ticked = () => {
    this.link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    this.user
      .attr("x", (d) => {
        return d.x - this.profileSize / 2;
      })
      .attr("y", (d) => {
        return d.y - this.profileSize / 2;
      });

    this.rect
      .attr("x", (d) => {
        this.sizeCheck(d);
        return d.x - d.width / 2;
      })
      .attr("y", (d) => {
        return d.y - d.height + 10;
      });

    this.text.attr("x", (d) => d.x).attr("y", (d) => d.y);
  };

  sizeCheck = (d) => {
    const x = Math.abs(d.x);
    const y = Math.abs(d.y);
    const nodeWidth = Math.abs(d.width);
    const nodeHeight = Math.abs(d.height);
    const padding = 50; // 패딩 값으로 그래프의 크기 조정
    const minSize = 200; // 최소 크기 제한 값

    const maxX = Math.max(x + nodeWidth / 2 + padding, this.width / 2);
    const maxY = Math.max(y + nodeHeight / 2 + padding, this.height / 2);

    this.width = Math.max(2 * maxX, minSize);
    this.height = Math.max(2 * maxY, minSize);

    this.svg.attr("viewBox", [
      -this.width / 2,
      -this.height / 2,
      this.width,
      this.height,
    ]);
  };

  select = (nodeId) => {
    let userArray = [];
    let userTitleArray = [];
    this.text.attr("opacity", (d) => {
      if (nodeId == undefined) {
        return 1;
      }
      return nodeId == d.id ? 1 : 0.1;
    });

    this.link.attr("opacity", (d) => {
      if (nodeId == undefined) {
        return 1;
      }
      if (d.source.id == nodeId) {
        userArray.push(d.target.id);
        userTitleArray.push(d.target.title);
        return 1;
      }
      return 0.1;
    });
    this.user.attr("opacity", (d) => {
      if (nodeId == undefined) {
        return 1;
      }
      return userArray.includes(d.id) ? 1 : 0.1;
    });
    this.rect.attr("opacity", (d) => {
      if (nodeId == undefined) {
        return 1;
      }
      return nodeId == d.id ? 1 : 0.1;
    });
    return userTitleArray;
  };
}
export default ForceGraph;
