// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Korea } from 'flatpickr/dist/l10n/ko.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/airbnb.css'
import { DatasetController } from 'chart.js'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
    static values = { min: String, max: String}
    initialize() {
        // sets your language (you can also set some global setting for all time pickers)
        this.config = {
            locale: document.documentElement.lang,
            mode: 'range',
            dateFormat: "Y-m-d",
            minDate: new Date(this.minValue),
            maxDate: new Date(this.maxValue),
            onReady: this.onReady,
            onChange: this.onChange,
            disableMobile: "true",
            onClose: this.onClose,
        }
    }
    connect() {
        super.connect();
        this.config = {
            ...this.config,
        }
    }
    onReady(selectedDates, dateStr, instance){
        instance.l10n.rangeSeparator  =" ~ ";
        const timefield = document.getElementById("event_time_field");
        if(timefield == null) return;
        if( dateStr.includes("~"))
        {
            timefield.style.setProperty( 'display', 'none', 'important' );
            instance.element.parentElement.nextElementSibling.style.display = 'none'
        }
        else
        {
            timefield.style.display = "";
            instance.element.parentElement.nextElementSibling.style.display = ''
        }
    }
    onClose( selectedDates, dateStr, instance){
        if(selectedDates.length == 1){
            instance.setDate([selectedDates[0],selectedDates[0]], true);
        }
    }
    onChange( selectedDates, dateStr, instance){
        const timefield = document.getElementById("event_time_field");
        if(timefield == null) return;
        if( dateStr.includes("~"))
        {
            timefield.style.setProperty( 'display', 'none', 'important' );
            instance.element.parentElement.nextElementSibling.style.display = 'none'
        }
        else
        {
            timefield.style.display = "";
            instance.element.parentElement.nextElementSibling.style.display = ''
        }
    }
}
