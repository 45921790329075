// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import { Controller } from '@hotwired/stimulus'

import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Korea } from 'flatpickr/dist/l10n/ko.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/airbnb.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
    static values = { time: Number }
    initialize() {
        // sets your language (you can also set some global setting for all time pickers)
        this.config = {
            locale: document.documentElement.lang,
            defaultDate: this.hasTimeValue ? this.timeValue : '',
        }
    }
    connect() {
        super.connect();
        this.config = {
            ...this.config,
        }
    }
}
