import { Controller } from '@hotwired/stimulus'

import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";


export default class extends Controller {
    static values = { href: String,
                      saveUrl: String}
    connect() {
    }
    editUser(e){
        let td = e.target;
        if (e.target.nodeName !== 'TD')
            td = e.target.closest('td')
        if (td.classList.contains('except_move'))
            return;

        if(this.hasHrefValue){
            window.document.location = this.hrefValue;
        }
    }
    getMetaValue(key){
        const token = document.getElementsByName(
            key
            )[0].content;
        return token;
    }
    notyMessage(msg, type) {
        new Noty({
          text: msg,
          type: type,
          layout: "topRight",
          theme: "bootstrap-v4",
          progressBar: true,
          timeout: 3000,
        }).show();
      }
    saveJudge2User(e){
        let formData = new FormData()
        formData.append("user[judge_group_id]", e.target.value);
        formData.append("method", 'patch');
        fetch(this.saveUrlValue, {
            body: formData,
            method: 'PATCH',
            dataType: 'script',
            credentials: "include",
            headers: {
                    "X-CSRF-Token": this.getMetaValue("csrf-token")
             },
      }).then(response =>{
        if(response.status === 200)
        {
            this.notyMessage('저장되었습니다', 'success')
        }
        else{
            this.notyMessage('저장되지 않았습니다', 'error')
        }}
      );

    }
}
