import { Controller } from '@hotwired/stimulus'
import { Swiper, Navigation, Pagination, SwiperOptions, Autoplay, EffectCards } from 'swiper'
import 'swiper/swiper-bundle.min.css';

export default class extends Controller {
    static values = { options: Object, initialSlide: Number }
    static targets = [ 'ranks', 'questions', 'prev', 'next', 'pagination', 'channel', 'finalRank', 'timer']

    connect() {
        let initslide = this.initialSlideValue > 0 ? this.initialSlideValue: 0;
        this.swiper = new Swiper(this.element, {
            modules: [Navigation, Pagination, Autoplay, EffectCards],
            effect: "cards",
            ...this.optionsValue,
            initialSlide: initslide,
        })

        this.timerController = null;
        this.state = 'start';

        this.resetButton();
        this.disableButton(this.nextTarget);
    }
    init(event){
        this.perform('start', 0);
    }
    setTimerController(controller){
        this.timerController = controller;
    }
    performQuestion(action, question_id){
        if(question_id === null) return;
        const controller = this.application.getControllerForElementAndIdentifier( this.questionsTargets[this.swiper.slides[this.swiper.realIndex + 1].dataset.seq-1], 'question-dashboard');
        if(controller !== null){
            if(action === 'open'){
                controller.open();
            }
            else if(action === 'close'){
                controller.close();
            }
        }
    }
    perform(action, question_id){
        this.state = action;
        const channelController = this.application.getControllerForElementAndIdentifier(this.channelTarget, 'quiz-dashboard-channel');
        channelController.perform(action, question_id);

        this.performQuestion(action, question_id);

    }
    resetButton(){
        this.checkNext();
        this.checkPrev();
    }
    checkPrev(){
        if(this.swiper.isBeginning){
            this.disableButton(this.prevTarget);
            this.disableButton(this.nextTarget);
        }
        else{
            this.enableButton(this.prevTarget);
        }
    }
    checkNext(){
        if(this.swiper.isEnd){
            this.disableButton(this.nextTarget);
        }
        else{
            this.enableButton(this.nextTarget);
        }
    }
    enableButton(target){
        target.classList.replace("tw-text-gray-600", "tw-text-white");
        target.classList.add("tw-cursor-pointer");
    }
    disableButton(target){
        target.classList.replace("tw-text-white", "tw-text-gray-600");
        target.classList.remove("tw-cursor-pointer");
    }
    gotoNext(event){
        if(event !== null && event.target.classList.contains('tw-text-gray-600')) return;
        if(event !== null && this.timerController !== null) {
            this.timerController.stopTimer();
        }
        if(this.swiper.slides.length - 2 === this.swiper.realIndex){
            const controller = this.application.getControllerForElementAndIdentifier( this.finalRankTarget, 'quiz-dashboard-final-rank');
            controller.fetchResult();
            this.timerController.stopTimer();
            this.perform('final_rank', 0);
        }
        else if(this.state === 'open'){
            this.perform('close', this.swiper.slides[this.swiper.realIndex + 1].dataset.questionId);
            return;
        }
        else if(this.state === 'start' || this.state === 'rank'){
            this.paginationTarget.innerText =
            document.documentElement.lang === "ko" ? `${this.swiper.slides[this.swiper.realIndex + 1].dataset.seq} 번째 문제 / ` : `${this.swiper.slides[this.swiper.realIndex + 1].dataset.seq} / `
            this.timerController.startTimer();


            this.perform('open', this.swiper.slides[this.swiper.realIndex + 1].dataset.questionId);
        }
        else if(this.state === 'close'){
            const controller = this.application.getControllerForElementAndIdentifier( this.ranksTargets[this.swiper.slides[this.swiper.realIndex + 1].dataset.seq-1], 'question-dashboard-rank');
            controller.fetch_result();
            this.perform('rank', this.swiper.slides[this.swiper.realIndex + 1].dataset.questionId);
        }
        this.swiper.slideNext(this.changed_next);
        this.resetButton();
    }
    gotoPrev(event){
        if(event.target.classList.contains('tw-text-gray-600')) return;
        if(event !== null && this.timerController !== null) {
            this.timerController.stopTimer();
        }
        if(this.state === 'final_rank'){
            const controller = this.application.getControllerForElementAndIdentifier( this.finalRankTarget, 'quiz-dashboard-final-rank');
            controller.stopTimer();
        }
        else if(this.state === 'open'){
            this.timerController.stopTimer();
        }
        if(this.swiper.realIndex - 1 === 0){
            this.perform('start', 0);
        }

        this.swiper.slidePrev();
        this.resetButton();

    }
    start(){
        this.timerController.startTimer();
        this.enableButton(this.nextTarget)
    }
    gotoFirst(event){
        if (this.swiper === null) return;
        this.swiper.slideTo(0);
    }
    disconnect() {
        this.swiper.destroy()
        this.swiper = undefined
    }
    currentAction(){
        return this.state
    }
    get defaultOptions() {
        return {}
    }
}

