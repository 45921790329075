import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
export default class extends Controller {

    static values = { meetingId: Number, battleId: Number, url: String }

    connect() {
        this.running = false;
        this.channel = new Channel('BattleDashboardChannel', this, { meeting_id: this.meetingIdValue, stage_item_type: "Battle", battle_id: this.battleIdValue, stage_item_id: this.battleIdValue })
    }
    error(payload) {
        new Noty({
            text: data.message,
            type: "error",
            theme: "bootstrap-v4",
            layout: "topRight",
            timeout: 3000,
          }).show();
    }
    fatal(payload) {
        console.log('fatal');
        console.log(payload);
        location.href = "/";
    }
    reload(payload) {
        window.location.reload();
    }
    process(){
        if(this.running) {
            return;
        }
        this.running = true;

        $.get(this.urlValue, function(data) {
            if(data){
                $('.js-option').each(function(index, elm) {
                    var option_slug = $(elm).data('option-slug');
                    var data_option = data.battle[option_slug];
                    console.log($(elm).find('.js-favors-count'))
                    var old_count = parseInt($(elm).find('.js-favors-count').text());
                    var new_count = data_option.favors_count;
                    if(old_count !== new_count) {
                    $(elm).find('.js-favors-count').text(new_count);
                    }

                    $(elm).find('.js-graph-percentage').css({ "height": (data_option.percentage + "%") });
                });
            }
        }).always(
            this.running = false
        );
    }
    favors(payload) {
          if(!payload || payload.length <= 0) {
            return
          }
          var need_to_update = false
          payload.payload.forEach(function(item) {
            if (item.current_juror_width !== '')
            {
                var $option = $('#' + item['option_dom_id']);
                $option.find('.js-juror').css({"width":item.current_juror_width, "height":item.current_juror_width})

                var $contra_option = $('#' + item['contra_option_dom_id']);
                $contra_option.find('.js-juror').css({"width":item.contra_juror_width, "height":item.contra_juror_width})
            }
            switch(item.action) {
              case 'create':
                // 혹시 남아있을지도 모르는 해당 사용자의 투표를 지웁니다
                var $garbage_favor = $('.js-juror[data-user-id="' + item['user_id'] + '"]');
                $garbage_favor.slideToggle(function() { this.remove(); });

                var $option = $('#' + item['option_dom_id']);
                $(item['favor']).appendTo($option.find('.js-favors')).hide().slideToggle();
                need_to_update = true;
                break;
              case 'change':
                var $favor = $('#' + item['favor_dom_id']);
                var $option = $('#' + item['option_dom_id']);

                if($favor.length) {
                  var oldOffset = $favor.offset();
                  var $newVote = $(item['favor']).appendTo($option.find('.js-favors'));
                  var newOffset = $newVote.offset();
                  $newVote.after(' ');
                  $newVote.hide();

                  var $proxyVote = $favor.clone().appendTo(('body'));
                  $proxyVote.css({
                    'position': 'absolute',
                    'left': oldOffset.left,
                    'top': oldOffset.top,
                    'z-index': 9999999,
                    'width': 38,
                    'height': 38
                  });
                  $favor.remove();

                  $proxyVote.animate({'top': newOffset.top, 'left': newOffset.left}, 'fast', function(){
                    $newVote.show();
                    $proxyVote.remove();
                  });
                } else {
                  $(item['favor']).appendTo($option.find('.js-favors')).hide().slideToggle();
                }
                need_to_update = true;
                break;
              case 'destroy':
                var $garbage_favor = $('.js-juror[data-user-id="' + item['user_id'] + '"]');
                $garbage_favor.slideToggle(function() { this.remove(); });
                need_to_update = true;
                break;
            }
          });
          if(need_to_update) {
            this.process();
          }
    }
}
