import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
export default class extends Controller {
    static targets = ['title', 'chart', 'point', 'chartbar', 'ratingUsersCount']
    static values = { meetingId: Number, bordaId: Number, url: String }

    animateNumber($elm, to) {
        if (isNaN(to) || to < 0) {
            to = 0;
        }
        $elm.each(function() {
            var from = $(this).text();
            var from = parseInt(from);
            if (isNaN(from)) {
                from = 0;
            }

            if (from == to) return;

            $(this)
                .prop("counter", from)
                .animate({
                    counter: to,
                }, {
                    duration: 300,
                    easing: "swing",
                    step: function(now) {
                        $(this).text(Math.ceil(now));
                    },
                    complete: function() {
                        if (to <= 0) {
                            $(this).text("");
                        } else {
                            $(this).text(to);
                        }
                    },
                });
        });
    }
    updateRatings(dashboard_data_url) {
        if (this.running) {
            return;
        }
        this.running = true;
        fetch(dashboard_data_url)
            .then(response => response.json())
            .then(data => {

                if (data) {
                    if (!data.opened) {
                        return;
                    }
                }

                data.this = this;
                data.candidates.forEach(function(candidate, index) {
                    var $candidate = $(data.this.chartbarTargets[index])
                    var percentage = candidate.percentage;

                    if (percentage <= 0) {
                        $candidate.css("width", "0");
                        $candidate.addClass("chart-bar-zero");
                    } else {
                        $candidate.css("width", percentage + "%");
                        $candidate.removeClass("chart-bar-zero");
                    }
                    candidate.choice_percentages.forEach(function(choice_percentage) {
                        var choice_id = choice_percentage["choice_id"];
                        var point_percentage = choice_percentage["percentage"];
                        if (isNaN(point_percentage)) {
                            point_percentage = 0;
                        }
                        var choice = $candidate
                            .find(
                                ".js-dashboard-borda-candidate-subbar.choice-" +
                                choice_id
                            );
                        choice.css("width", point_percentage + "%");
                        data.this.animateNumber(
                            $(data.this.pointTargets[index]),
                            candidate.total_point
                        );
                    })
                    var wait = setInterval(function() {
                        if (!$(data.this.pointTarget[index]).is(":animated")) {
                            clearInterval(wait);
                            data.this.running = false;
                        }
                    }, 100);
                })

                $(data.this.ratingUsersCountTarget).text(data.rating_users_count);
            })

    }

    connect() {
        this.running = false;
        this.channel = new Channel('BordaDashboardChannel', this, { meeting_id: this.meetingIdValue, stage_item_type: "Borda", borda_id: this.bordaIdValue, stage_item_id: this.bordaIdValue })

    }
    disconnect() {

    }

    rating(payload) {
        if (payload.borda_id != this.bordaIdValue) return;
        this.updateRatings(this.urlValue);
    }
    error(payload) {
        new Noty({
            text: data.message,
            type: "error",
            theme: "bootstrap-v4",
            layout: "topRight",
            timeout: 3000,
          }).show();
    }
    fatal(payload) {
        console.log('fatal');
        console.log(payload);
        location.href = "/";
    }
    reload(payload) {
        window.location.reload();
    }
}
