import { Controller } from '@hotwired/stimulus'
import SignaturePad from 'signature_pad'
import { fetchResponseCheck } from '../libs/fetch_check_response'
import CryptoJS from 'crypto-js'
export default class extends Controller {
    static targets = ['canvas', 'input']
    static values = { url: String, encpad: String, statusMeeting: Boolean}
    connect() {
        this.signaturePad = new SignaturePad(this.canvasTarget);
        this.fit_ratio();
        this.load_sig();
        if(this.statusMeetingValue === false)
            this.signaturePad.off();
    }

    fit_ratio(){
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
        this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;
        this.canvasTarget.getContext("2d").scale(ratio, ratio);
    }

    resize(evt) {
        this.fit_ratio();
        this.load_sig();
    }
    load_sig(){
        if (this.signaturePad == null) return;
        if (this.encpadValue == null || this.encpadValue == '') return;
        this.signaturePad.fromDataURL(['data:image/png;base64', this.encpadValue].join(','), {
            width: this.canvasTarget.offsetWidth,
            height: this.canvasTarget.offsetHeight
        });
    }
    clear(evt) {
        if (this.signaturePad == null) { return }
        this.encpadValue = '';
        this.signaturePad.clear();
        this.inputTarget.value = "";
    }
    save(evt) {
        if (this.signaturePad == null) { evt.preventDefault(); return; }
        this.inputTarget.value = this.signaturePad.toDataURL();
    }

}
