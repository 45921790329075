import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["item"];

    connect(){
      if(this.hasItemTarget && this.itemTarget.classList.contains('tw-hidden') === false)
      {
        this.body_disable_scroll();
      }
    }
    disconnect(){
      if(this.hasItemTarget && this.itemTarget.classList.contains('tw-hidden') === false)
      {
          this.body_enable_scroll();
      }
    }
    body_disable_scroll(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = "no";
    }
    body_enable_scroll(){
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = "yes";
    }
    is_open(element){
      element.style.opacity = 1;
      element.style.visibility = 'visible';
    }
    is_close(element){
      element.style.opacity = 0;
      element.style.visibility = '';
    }
    open() {
        this.element.style.display = 'block';
        this.element.classList.add("show");

        if(document.getElementsByClassName("modal-overlay").length == 0)
        {
          let page_container = document.getElementById('js-page-container');
          if(page_container != null)
          {
            var modal_overlay = document.createElement("div");
            modal_overlay.classList.add("modal-overlay");
            page_container.appendChild(modal_overlay);
            this.is_open(modal_overlay);
          }
        }
        else
        {
          this.is_open(document.getElementsByClassName("modal-overlay")[0]);

        }
        if(document.getElementById("js-notice-next"))
        {
          document.getElementById("js-notice-next").classList.add('show')
          document.getElementById("js-notice-next").classList.remove('fade')
        }
        this.body_disable_scroll();

    }

    close() {
        this.element.style.display = 'none';
        this.element.classList.remove("show");

        if(document.getElementsByClassName("modal-overlay").length > 0)
        {
          this.is_close(document.getElementsByClassName("modal-overlay")[0]);
        }
        if(document.getElementById("js-notice-next"))
        {
          document.getElementById("js-notice-next").classList.remove('show')
          document.getElementById("js-notice-next").classList.add('fade')
        }
        this.body_enable_scroll();
    }
}
