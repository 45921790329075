import { Controller } from '@hotwired/stimulus'
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
import Channel from '../channels/channel';

export default class extends Controller {
    static values = { meetingId: Number,
        quizId: Number, questionId: Number, userId: Number,
        quizPurpose: String, type:String}
    static targets = [ 'check', 'bg', 'answerlight', 'number']
    connect(){
        this.show_results = false;
        this.reset();
    }
    background_on(bg){
        if(this.typeValue === 'one_select'){
            bg.classList.remove('tw-bg-gray-800');
            bg.style.backgroundImage = `linear-gradient(rgba(111, 75, 249, 0.7), rgba(111, 75, 249, 0.7)), url(${bg.dataset.img})`
            bg.style.backgroundBlendMode = 'multiply';
        }
        else{
            bg.classList.add('tw-bg-purple-200');
        }
    }
    background_off(bg){
        if(this.typeValue === 'one_select'){
            bg.classList.remove('tw-bg-gray-800');
            bg.style.backgroundImage = `linear-gradient(rgba(52, 53, 61, 0.7), rgba(52, 53, 61, 0.7)), url(${bg.dataset.img})`
            bg.style.backgroundBlendMode = 'multiply';
        }
        else{
            bg.classList.remove('tw-bg-purple-200');
        }
    }
    highlight_on(bg){
        bg.classList.replace('tw-border-2', 'tw-border-4');
        bg.classList.replace('tw-border-white', 'tw-border-red');
        this.answerlightTargets.forEach( answerlight => {
            if( answerlight.dataset.option === bg.dataset.option){
                answerlight.classList.remove('tw-hidden');
            }
        })
    }
    highlight_off(bg){
        bg.classList.replace('tw-border-4', 'tw-border-2');
        bg.classList.replace('tw-border-red', 'tw-border-white');
        this.answerlightTargets.forEach( answerlight => {
            if( answerlight.dataset.option === bg.dataset.option){
                answerlight.classList.add('tw-hidden');
            }
        })
    }
    init(){
        this.show_results = false;
        this.checkTargets.forEach( check => {
            check.classList.add('tw-hidden')
        })
        this.bgTargets.forEach( bg => {
            this.background_off(bg);
            bg.classList.add('tw-cursor-pointer')
            this.highlight_off(bg);
        })
        this.numberTargets.forEach( number => {
            number.innerHTML = number.dataset.seq;
        })
    }
    reset(){
        if (this.quizPurposeValue === 'just_quiz'){
            this.checkTargets.forEach( check => {
                if (check.dataset.answer === 'true'){
                    check.classList.toggle('tw-hidden')
                }
            })
            this.bgTargets.forEach( bg => {
                if (bg.dataset.answer === 'true'){
                    this.background_on(bg);
                }
                this.highlight_off(bg);

                bg.classList.add('tw-cursor-pointer')
            })
            this.numberTargets.forEach( number => {
                if (number.dataset.answer === 'true'){
                    number.innerHTML = "<i class='fa fa-check'></i>";
                }
                else{
                    number.innerHTML = number.dataset.seq;
                }
            })
        }
    }
    show_result(){
        this.show_results = true;
        this.bgTargets.forEach( bg => {
            if( bg.dataset.right === 'true'){
                this.highlight_on(bg);
            }
            else{
                this.highlight_off(bg);
            }
            bg.classList.remove('tw-cursor-pointer')
        })

    }
    answer(event){
        if (this.show_results === true) {
            return;
        }
        this.fetch_result(event.params.option);
    }
    fetch_result(option_id){
        fetch(`/quizzes/${this.quizIdValue}/answers?question=${this.questionIdValue}&quiz_option=${option_id}&user=${this.userIdValue}`)
        .then((response) => {
          if(response.status === 200){
            return response.json();
          }
          else if(response.status === 204){
            new Noty({
                text: '타운홀이 종료되었습니다. 답이 적용되지 않습니다.',
                type: "error",
                theme: "bootstrap-v4",
                layout: "center",
                timeout: 3000,
              }).show();
            return;
          }
        })
        .then((json) => {
            this.checkTargets.forEach( check => {
                if (json.action != 'cancel' && Number(check.dataset.option) === json.option){
                    check.classList.remove('tw-hidden');
                }
                else{
                    check.classList.add('tw-hidden');
                }
            })
            this.bgTargets.forEach( bg => {
                if (json.action != 'cancel' && Number(bg.dataset.option) === json.option){
                    this.background_on(bg);
                }
                else{
                    this.background_off(bg);
                }
            })
            this.numberTargets.forEach( number => {
                if (json.action != 'cancel' && Number(number.dataset.option) === json.option){
                    number.innerHTML = "<i class='fa fa-check'></i>"
                }
                else{
                    number.innerHTML = number.dataset.seq;
                }
            })
        })

    }
}
