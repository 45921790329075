// comment_controller.js
import { Controller } from '@hotwired/stimulus'

import Channel from '../channels/channel'
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';

export default class extends Controller {
  static targets = ["commentForm", "commentBody", "commentId", "submit", "reset", "commentPanel",
  "commentHotPanel", "noComments", "noHotComments", "commentCount", "hotCommentCount", "commentContent",
  "commentFormBox", "fakedCommentFormBox", "formCounter", "imageInput", "imagePreview", "imageInputButton"];

  static values = { meetingId: Number, commentableId: Number, commentType: String }

  connect() {
    if ( this.meetingIdValue === 0 ) { return; }
    this.commentChannel = new Channel('CommentChannel', this, { meeting_id: this.meetingIdValue })

    this.fakedCommentFormBoxTarget.classList.remove('tw-hidden');
    this.commentFormBoxTarget.classList.add("tw-hidden");
    this.formCounterTarget.textContent = '';

    this.checkNoComments();
    this.updateAllTimeTags();
    this.interval = setInterval(() => {
      this.updateAllTimeTags();
    }, 60000); // 예: 1분마다 업데이트

    this.needToContinue = true;
    this.checkAndUpdateHotComments();

    // 초기화 로직
  }

  updateCounter(event) {
    const bodyLength = this.commentBodyTarget.value.length;
    this.formCounterTarget.textContent = bodyLength;
  }

  checkAndUpdateHotComments() {
    if (this.hasCommentHotPanelTarget) {
      this.commentHotPanelTargets.forEach((elm) => {
        if (elm.dataset.needToUpdate === "true") {
          this.updateCommentsFromServer(elm);
        }
        else{
          this.needToContinue = false;
        }
      });

      if (this.needToContinue) {
        setTimeout(() => this.checkAndUpdateHotComments(), 15 * 1000);
      }
    }
  }
  getMetaValue(key){
    const token = document.getElementsByName(
        key
        )[0].content;
    return token;
  }
  fadeIn(element, duration = 500) {
    element.style.opacity = 0;
    element.style.display = 'block';

    let last = +new Date();
    let tick = () => {
      element.style.opacity = +element.style.opacity + (new Date() - last) / duration;
      last = +new Date();

      if (+element.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      }
    };

    tick();
  }
  updateCommentsFromServer(elm) {
    const url = elm.dataset.hotPanelListUrl;
    fetch(url,{
      method: 'GET', // 또는 'POST', 'PUT', 'DELETE' 등
      credentials: "include",
      headers: {
              "X-CSRF-Token": this.getMetaValue("csrf-token")
        },
    })
    .then(response => {
      if (response.ok) {
        this.checkNoComments();
      } else {
        console.error('서버 에러');
        this.needToContinue = false;
      }
    })
    .catch(error => {
        console.log(error);
        // alert("로그아웃되었거나 다른 타운홀에 로그인되어 있습니다. 다시 로그인해 주세요.");
        // location.href = "/";
        this.needToContinue = false;
    });
  }

  destory(event) {
    if(!this.commentChannel) {
      alert('서버와 연결이 끊어졌습니다. 페이지를 새로 고칩니다.');
      window.location.reload();
    }
    var comment_id = event.params.id;
    if(confirm('정말 지우시겠습니까?')) {
      this.commentChannel.perform('destroy', {
        comment_id: comment_id,
        meeting_id: this.meetingIdValue,
        commentable_id: this.commentableIdValue,
        commentable_type:this.commentTypeValue
      });
    }
    this.needToContinue = true;
    this.checkAndUpdateHotComments();
    event.preventDefault()
  }

  update(event){
    event.preventDefault();

    const commentId = event.currentTarget.dataset.commentId;
    const commentBody = event.currentTarget.dataset.commentBody;
    const commentImage = event.currentTarget.dataset.commentImage;
    const commentImageUrl = event.currentTarget.dataset.commentImageUrl;

    if (commentImageUrl !== undefined) {
      this.imagePreviewTarget.src = commentImageUrl;
      this.imageInputButtonTarget.textContent = "이미지 변경"
    }

    if (this.hasImageInputTarget) {
      this.imageInputTarget.dataset.commentImageId = commentImage;
    }
    this.commentFormTarget.querySelector('#comment_id').value = commentId;
    this.commentBodyTarget.value = commentBody;
    this.updateCounter()
    this.commentBodyTarget.focus();
    this.submitTarget.value = this.submitTarget.dataset.updateValue;
    this.openCommentForm();

  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('image', file);

    // '/upload' 경로는 CommentImagesController의 업로드 액션에 매핑되어야 합니다.
    return fetch('/upload_comment_image', {
      method: 'POST',
      body: formData,
      credentials: 'include', // 쿠키/세션과 같은 credentials 포함 (필요한 경우)
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content') // CSRF 토큰 포함
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data.commentImageId; // 서버로부터 받은 이미지 URL을 반환합니다.
    })
    .catch(error => console.error('Error:', error));
  }

  // 메시지 전송 함수
  sendMessage(commentImageId = null) {

    this.commentChannel.perform("save", {
      id: this.commentFormTarget.querySelector("#comment_id").value,
      body: this.commentBodyTarget.value,
      commentImageId: commentImageId === null ? this.imageInputTarget.dataset.commentImageId: commentImageId,
      meeting_id: this.meetingIdValue,
      commentable_id: this.commentableIdValue,
      commentable_type: this.commentTypeValue,
    });
    this.commentBodyTarget.value = "";
    this.imageInputTarget.value = '';
    this.imageInputTarget.dataset.commentImageId = '';
    this.imagePreviewTarget.src = '';
    this.updateCounter();
  }

  save(event) {
    event.preventDefault();
    if (this.imageInputTarget.files.length > 0) {
      const file = this.imageInputTarget.files[0];
      this.uploadFile(file)
        .then((imageData) => {
          // 이미지 URL을 받은 후에 메시지 전송
          this.sendMessage(imageData);
        })
        .catch(error => {
          console.error('Image upload failed:', error);
        });
    } else {
      // 이미지가 없는 경우 바로 메시지 전송
      this.sendMessage();
    }
    this.closeCommentForm();
  }
  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    if (this.commentChannel) {

      this.commentChannel.unsubscribe();
      this.commentChannel = null;
    }
  }

  fatal(payload) {
    alert(data.message);
    location.href = "/";
  }

  error(payload) {
    new Noty({
        text: payload.message,
        type: "error",
        theme: "bootstrap-v4",
        layout: "topRight",
        timeout: 5000,
      }).show();
  }

  loadComment(commentUrl) {
    fetch(commentUrl)
      .then(response => response.text())
      .then(html => {
        this.prependComment(html);
      })
      .catch(error => console.error("Error fetching comment:", error));
  }

  townhallApply(baseElement, query, callback) {
    const elements = baseElement.querySelectorAll(query);
    elements.forEach(elm => {
      callback(elm);
    });

    if (baseElement.matches(query)) {
      callback(baseElement);
    }
  }
  timeAgo(time) {
    const now = new Date();
    const past = new Date(time);
    const diff = now.getTime() - past.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return `1분 전`;
    } else if (minutes < 60) {
      return `${minutes}분 전`;
    } else if (hours < 24) {
      return `${hours}시간 전`;
    } else {
      return `${days}일 전`;
    }
  }

  checkNoComments(event) {

    if ( this.hasNoHotCommentsTarget === true ){
      const hotCount = this.commentHotPanelTarget.children.length;
      if (hotCount === 0) {
        this.noHotCommentsTarget.classList.remove('tw-hidden');
      } else {
        if (!this.noHotCommentsTarget.classList.contains('tw-hidden')){
          this.noHotCommentsTarget.classList.add('tw-hidden');
        }
      }
    }

    if (this.commentCountTarget.textContent > 0 && !this.noCommentsTarget.classList.contains("tw-hidden")) {
      this.noCommentsTarget.classList.add("tw-hidden");
    }

    if (this.commentCountTarget.textContent == 0) {
      this.noCommentsTarget.classList.remove("tw-hidden");
    }
  }
  updateAllTimeTags() {
    const timeTags = this.element.querySelectorAll('time');
    timeTags.forEach(timeTag => {
      const dateTime = timeTag.getAttribute('datetime');
      timeTag.textContent = this.timeAgo(dateTime);
    });
  }

  applyTimeago(commentElement) {
    const timeElements = commentElement.querySelectorAll('time');
    timeElements.forEach(timeElm => {
      const dateTime = timeElm.getAttribute('datetime');
      timeElm.textContent = this.timeAgo(dateTime);
    });
  }

  prependComment(html) {
    const commentElement = document.createElement('div');
    commentElement.innerHTML = html;
    this.commentPanelTarget.prepend(commentElement);

    this.townhallApply(commentElement , '.js-comment-btns', function(elm) {
      var comment_user_id = $(elm).data('comment-user-id');
      if(gon.current_user_id == comment_user_id) {
        $(elm).show();
      }
    });
    this.applyTimeago(commentElement);
  }

  commentUpvoted(event) {
    if (this.hasCommentHotPanelTarget === false) {
      return;
    }
    this.commentHotPanelTarget.dataset.needToUpdate = true;
  }

  created(payload) {

    if(payload['commentable_type'] != this.commentTypeValue ||
    payload['commentable_id'] != this.commentableIdValue) {
      return;
    }
    let comment_url = payload['comment_url'];
    let comment_user_id = payload['comment_user_id'];
    let comments_count = payload['comments_count'];

    this.loadComment(comment_url);

    this.commentCountTarget.textContent = comments_count;
    this.commentPanelTarget.classList.remove("tw-hidden");
    this.commentUpvoted(null);
    this.checkNoComments();
  }

  loadUpdatedComment(commentUrl, oldComment) {
    fetch(commentUrl)
      .then(response => response.text())
      .then(html => {
        const newComment = document.createElement('div');
        newComment.innerHTML = html;
        oldComment.replaceWith(newComment);
        this.commentPrepare(newComment);
      })
      .catch(error => console.error("Error fetching updated comment:", error));
  }


  applyCommentButtons(baseElement) {
    const commentButtons = baseElement.querySelectorAll('.js-comment-btns');
    commentButtons.forEach(elm => {
      const commentUserId = elm.dataset.commentUserId;
      if (gon.current_user_id === commentUserId) {
        elm.style.display = 'block';
      }
    });
  }

  commentPrepare(comment) {
    this.applyCommentButtons(comment);
    this.applyTimeago(comment);
  }

  updated(payload) {
    if (payload['commentable_type'] !== this.commentTypeValue ||
    payload['commentable_id'] !== this.commentableIdValue) {
      return;
    }

    const oldComment = this.element.querySelector('.' + payload['dom_class']);
    if (oldComment) {
      this.loadUpdatedComment(payload['comment_url'], oldComment);
    }

    this.commentCountTarget.textContent = payload['comments_count'];
  }

  destroyed(payload) {

    if (payload['commentable_type'] !== this.commentTypeValue ||
    payload['commentable_id'] !== this.commentableIdValue) {
      return;
    }

    const commentElement = document.querySelector('.' + payload['dom_class']);
    if (commentElement) {
      commentElement.classList.add('tw-animate-fade'); // CSS에서 fade-out 애니메이션 정의 필요
      setTimeout(() => {
        commentElement.remove();
        this.commentUpvoted(null);

      }, 500); // 500ms는 fade-out 애니메이션 시간에 맞춰 조정
    }

    this.commentCountTarget.textContent = payload['comments_count'];
    this.checkNoComments();
  }

  upvoted(payload) {
    const upvotesCountElement = this.element.querySelector('.' + payload['upvotes_count_dom_class'] + ' .counter');
    if (upvotesCountElement) {
      if (payload['upvotes_count'] === '0') {
        upvotesCountElement.textContent = '';
      } else {
        upvotesCountElement.textContent = payload['upvotes_count'];
      }
    }

    this.commentCountTarget.textContent = payload['comments_count'];
    this.commentUpvoted(null);
    this.needToContinue = true;
    this.checkAndUpdateHotComments();
    this.checkNoComments();
  }

  closeCommentForm() {
    this.fakedCommentFormBoxTarget.classList.remove('tw-hidden');
    if (!this.commentFormBoxTarget.classList.contains('tw-hidden')){
      this.commentFormBoxTarget.classList.add("tw-hidden");
    }
    this.submitTarget.value = this.submitTarget.dataset.createValue;

  }

  openCommentForm() {
    if (!this.fakedCommentFormBoxTarget.classList.contains('tw-hidden')){
      this.fakedCommentFormBoxTarget.classList.add('tw-hidden');
    }
    this.commentFormBoxTarget.classList.remove("tw-hidden");
    this.commentBodyTarget.focus();
  }

  editComment(event) {
    event.preventDefault();
    this.commentFormTarget.reset();
    this.commentFormTarget.querySelector("#comment_id").value = null;
    this.commentBodyTarget.classList.add('tw-h-auto');

    this.submitTarget.value = this.submitTarget.dataset.createValue;

    this.imagePreviewTarget.src = '';
    this.imageInputTarget.value = '';
    this.openCommentForm();
  }
  resetForm(event) {
    event.preventDefault();

    this.closeCommentForm();

    this.commentFormTarget.reset();
    this.commentFormTarget.querySelector("#comment_id").value = null;
    this.commentBodyTarget.classList.add('tw-h-auto');
    this.submitTarget.value = this.submitTarget.dataset.createValue;

    this.imagePreviewTarget.src = '';
    this.imageInputTarget.value = '';
    this.updateCounter()

  }
  scrollTop() {
    this.commentPanelTarget.scrollTop = 0;
  }
}
