import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'

export default class extends Controller {
  static targets = ['count', 'image']
  static values = { meetingId: Number, attendanceId: Number, attendees: Array }

  connect() {
    this.channel = new Channel("AttendanceDashboardChannel", this, {
      meeting_id: this.meetingIdValue,
      stage_item_type: "Attendance",
      attendance_id: this.attendanceIdValue,
    });

  }

  create(data) {
    this.countTarget.innerHTML = data.total_number;
    this.addImage()
  }

  addImage() {
    fetch('/attendances/'+this.attendanceIdValue+'/result')
      .then(res => res.text())
      .then(html => {
        this.imageTarget.innerHTML = html;
      })
  }

  retrieveData() {
  }


}
