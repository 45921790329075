import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-helper"
export default class extends Controller {
  static targets = ["text", "type", "progress", "name", "date"]
  connect() {
  }
  query(){
    let uri;
    if(this.hasNameTarget && this.hasDateTarget)
    {
      uri = `/admin?filterrific%5Bsearch_query%5D=${encodeURI(this.textTarget.value)}&filterrific%5Bwith_plan%5D=${encodeURI(this.typeTarget.value)}&filterrific%5Bwith_available%5D=${encodeURI(this.progressTarget.value)}&filterrific%5Bsearch_name%5D=${encodeURI(this.nameTarget.value)}&filterrific%5Bevent_date%5D=${encodeURI(this.dateTarget.value)}`
    }
    else
    {
      uri = `/admin?filterrific%5Bsearch_query%5D=${encodeURI(this.textTarget.value)}&filterrific%5Bwith_plan%5D=${encodeURI(this.typeTarget.value)}&filterrific%5Bwith_available%5D=${encodeURI(this.progressTarget.value)}`
    }
    location.assign(uri)
  }
  query_text(evt){
    if(evt.keyCode !== 13) return;
    this.query()
  }
}
