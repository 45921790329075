import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["btn"];
    connect() {
        this.expand_menu()
    }
    body_disable_scroll(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = "no";
    }
    body_enable_scroll(){
        document.documentElement.style.overflow = 'scroll';
        document.body.scroll = "yes";
    }
    expand_menu(){
        let node_aria = document.querySelector('#nav-menu')
        if(document.documentElement.clientWidth <= 640)
        {
            node_aria.style.display = 'none';
        }
        else if (document.documentElement.clientWidth > 1200)
        {
            node_aria.style.display = '';
            return;
        }


        let node_close_image = this.btnTarget.querySelector('.toggle-close-image')

        if(node_close_image.classList.contains('tw-hidden'))
        {
            node_aria.style.display = 'none';
            this.body_enable_scroll()
        }
        else
        {
            node_aria.style.display = '';
            if(document.documentElement.clientWidth <= 640)
                this.body_disable_scroll()
        }

    }

    layout(evt){
        clearTimeout(window.resizedFinished)
        window.resizedFinished = setTimeout(function(){
            let node_aria = document.querySelector('#nav-menu')

            if(document.documentElement.clientWidth <= 640)
            {
                node_aria.style.display = 'none';
            }
            else if (document.documentElement.clientWidth > 1200)
            {
                node_aria.style.display = '';
            }
        }, 250);

    }
    expand(evt){
        this.toggle(this.btnTarget);
        this.expand_menu();
    }
    img_onoff(img){
        if(img.classList.contains('tw-hidden'))
            img.classList.remove('tw-hidden')
        else
            img.classList.add('tw-hidden')
    }
    toggle(e){
        let node_menu_image = e.querySelector('.toggle-menu-image')
        let node_close_image = e.querySelector('.toggle-close-image')

        this.img_onoff(node_menu_image)
        this.img_onoff(node_close_image)
    }
}
