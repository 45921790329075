import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition"

export default class extends Controller {
    static targets = ["closeButton"]
  connect() {
    enter(this.element)
    enter(this.closeButtonTarget)

    setTimeout(() => {
      this.dismiss();
    }, 5000);
  }

  dismiss() {
    Promise.all([leave(this.element)],
        leave(this.closeButtonTarget),
        ).then(()=>{this.element.remove();}
        )

  }
}
