import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        type: String
      }
    connect() {
        this.layout();
    }

    invertViewport(){
        // do not create if viewport tag already exists
        if (document.querySelector('meta[name="viewport"]'))
            return;

        var viewPortTag=document.createElement('meta');
        viewPortTag.id="viewport";
        viewPortTag.name = "viewport";
        viewPortTag.content = "width=max-device-width, height=max-device-height,initial-scale=1.0";
        document.getElementsByTagName('head')[0].appendChild(viewPortTag);
    }
    isPortraitOrientation(){
        switch(window.orientation) {
            case -90:
            case 90:
            return false;
            }
            return true;
    }

    getDisplayWidth(){
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            if (this.isPortraitOrientation())
              return screen.width;
            else
              return screen.height;
          }

          return screen.width;
    }

    getDisplayHeight(){
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            if (this.isPortraitOrientation())
              return screen.height;
            else
              return screen.width;
          }

          // I subtract 180 here to compensate for the address bar.  This is imperfect, but seems to work for my Android tablet using Chrome.
          return screen.height - 180;
    }

    adjustViewport(requiredWidth, requiredHeight){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)){

            var actual_height = this.getDisplayHeight();
            var actual_width = this.getDisplayWidth();

            var min_width = requiredWidth;
            var min_height = requiredHeight;

            var ratio = Math.min(actual_width / min_width, actual_height / min_height);
            ratio = Math.min(0.8, ratio);
            console.log(window.devicePixelRatio);
            document.querySelector('meta[name="viewport"]').setAttribute('content', 'initial-scale=' + ratio + ', maximum-scale=' + (ratio*2) + ', minimum-scale=' + ratio + ', user-scalable=yes, width=' + actual_width);

            var x = (actual_width - min_width * ratio) / 2;
            var y = (actual_height - min_height * ratio) / 2;

            var box = document.getElementsByTagName('div')[0];
            if (ratio < 1) {
                var box = document.getElementsByTagName('div')[0];
                box.style.webkitTransform = 'scale('+ratio+')';
                box.style.webkitTransformOrigin = '0 0';
            }
        }
    }

    layout(){
        var width = this.getDisplayWidth();
        var height = this.getDisplayHeight();
        if(this.typeValue === "Battle")
        {
            height += 180;
        }
        if (this.typeValue === "Cheer")
        {
            height += 180;
        }
        this.adjustViewport(width, height);
    }
}

