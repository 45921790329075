import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['autoLink'];

  connect() {
    this.autoLinkTextChange();
  }

  autoLinkTextChange() {
    if (this.hasAutoLinkTarget) {
      this.autoLinkTargets.forEach((linkDoc) => {
        linkDoc.innerText = '(링크)';
      });
    }
  }
}
