import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';
import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";

export default class extends Controller {
    static values = { meetingId: Number,
        quizId: Number}
    static targets = [ 'slider', 'start' ]
    connect(){
        this.quizChannel = new Channel('QuizChannel', this, { meeting_id: this.meetingIdValue,
            quiz_id: this.quizIdValue });
    }

    perform(action, question_id){
        const controller = this.application.getControllerForElementAndIdentifier(this.sliderTarget, 'quiz-slider');
        controller.perform(action, question_id);
    }

    start(payload){
        this.perform('start', 0)
    }
    open(payload){
        this.perform("open", payload.question_id);
    }
    close(payload){
        this.perform("close", payload.question_id);
    }
    rank(payload){
        this.perform("rank", payload.question_id);
    }
    ready(payload){
        this.startTarget.textContent =
        document.documentElement.lang === 'ko'
        ? '잠시만 기다려 주세요. 곧 퀴즈가 시작됩니다.'
        : 'Please hold on a moment. Quiz starts soon.'
        ;
        this.perform('start', 0);
    }
    final_rank(payload){
        this.perform('final_rank', 0);
    }
    fatal(payload) {
        console.log("fatal");
        console.log(payload);
        location.href = "/";
      }
      error(payload) {
        new Noty({
          text: '뭔가 잘못되었습니다',
          type: "error",
          theme: "bootstrap-v4",
          layout: "topRight",
          timeout: 3000,
        }).show();
      }
      reload(payload) {
        window.location.reload();
      }
}
