import { Controller } from '@hotwired/stimulus'
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
export default class extends Controller {
  static values = {
    userCount: Number,
    type: String,
    url: String,
    fileName: String,
    disableWith: String,
    status: String,
    fileType: String,
    locale: String,
  };

  connect() {
    this.isRun = false;
    this.timer = null;
    this.originHTML = this.element.innerHTML;
    if (this.statusValue == 'processing') {
      this.element.innerHTML = this.disableWithValue;
      this.isRun = true;
      this.timer = setInterval(() => {
        this.refresh();
      }, 1000);
    }
  }

  disconnect() {
    this.isRun = false;
  }

  run() {
    if (this.isRun) {
      alert('파일을 생성중입니다. 기다려주세요.');
      return;
    }

    this.isRun = true;

    this.originHTML = this.element.innerHTML;
    this.element.innerHTML = this.disableWithValue;

    fetch(this.urlValue)
      .then((response) => response.blob())
      .then((blob) => {
        if (blob.type == 'application/zip') {
          var url = window.URL.createObjectURL(blob);
          this.downloadUrl(
            url,
            document.documentElement.lang === 'ko'
              ? '파일 다운로드가 완료되었습니다.'
              : 'Download completed!',
            'zip'
          );

          window.URL.revokeObjectURL(url);
        } else if (
          blob.type ==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          blob.type == 'application/excel'
        ) {
          var url = window.URL.createObjectURL(blob);
          this.downloadUrl(
            url,
            document.documentElement.lang === 'ko'
              ? '파일 다운로드가 완료되었습니다.'
              : 'Download completed!',
            'xlsx'
          );

          window.URL.revokeObjectURL(url);
        } else {
          blob.text().then((text) => {
            var parsed = JSON.parse(text);
            if (parsed.url == 'processing') {
              this.element.innerHTML = this.disableWithValue;
              if (this.timer == null) {
                this.timer = setInterval(() => {
                  this.refresh();
                }, 1000);
              }
            } else if (parsed.url !== null) {
              this.downloadUrl(
                parsed.url,
                '파일 다운로드가 완료되었습니다. 기존 데이터 생성 후 4시간이 지나면 새로운 데이터를 받을 수 있습니다. (발송 후 4시간이 경과된 메일을 통해 데이터 다운로드 불가)',
                'zip'
              );
            }
          });
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert(
          document.documentElement.lang === 'ko'
            ? '다운로드에 실패하였습니다.'
            : 'Download failed'
        );
        this.stopRefresh();
      });

  }
  refresh() {
    fetch(this.urlValue)
      .then((blob) => blob.json())
      .then((status) => {
        if (status.url != 'processing' && status.url != null) {
          this.stopRefresh();
          this.downloadUrl(
            status.url,
            '파일 다운로드가 완료되었습니다. 기존 데이터 생성 후 4시간이 지나면 새로운 데이터를 받을 수 있습니다. (발송 후 4시간이 경과된 메일을 통해 데이터 다운로드 불가)',
            'zip'
          );
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert(
          document.documentElement.lang === 'ko'
            ? '다운로드에 실패하였습니다.'
            : 'Download failed'
        );
        this.stopRefresh();
      });
  }
  downloadUrl(url, msg, ext) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    a.download = this.fileNameValue;
    document.body.appendChild(a);
    a.click();
    alert(msg);
    this.element.innerHTML = this.originHTML;
    this.isRun = false;
  }
  stopRefresh() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      this.element.innerHTML = this.originHTML;
      this.isRun = false;
    }
  }
}
