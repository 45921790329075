import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';

export default class extends Controller {
  static targets = ["modal"];
  static values = { meetingId: String }
  connect() {
    this.meetingChannel = new Channel('MeetingStatusChannel', this, { meeting_id: this.meetingIdValue });
    this.modal_controller();
  }
  modal_controller(){
    this.modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
  );
  }
  update_meeting_status(payload) {
    if (payload.meeting_id != parseInt(this.meetingIdValue)) return;
    if(this.modalController === null) {
      this.modal_controller();
    };

    if(this.modalController === null) return;
    if(payload.exists_now_item)
    {
      this.modalController.open();
    }
    else{
      this.modalController.close();
    }


  }
}
