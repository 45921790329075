import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['btns']

  showBtns() {
    if(this.hasBtnsTarget)
      this.btnsTarget.classList.replace('hide', 'show')
  }

  hideBtns() {
    if(this.hasBtnsTarget)
      this.btnsTarget.classList.replace('show', 'hide')
  }
}
