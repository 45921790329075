import { Controller } from "stimulus";
import GLightbox from 'glightbox';

export default class extends Controller {
  connect() {
    this.glightbox = GLightbox({
      touchNavigation: true,
      loop: true,
      autoplayVideos: true
    });
  }

  open(event) {
    event.preventDefault();
    const href = event.currentTarget.getAttribute('href');
    this.glightbox.setElements([
      {
        'href': href,
        'type': 'image',
        'width': 'full',
      }
    ])
    this.glightbox.open();
  }
}
