import { Controller } from '@hotwired/stimulus'

import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";
export default class extends Controller {
    static targets = ["datefield", "meetingid", "objective", "email", 'timefield', 'participants', 'plan', 'nonprofitPrice', 'profitPrice', 'participantsCount', 'amount', 'nonprofitAmount', 'amountForm']
    static values = {
        isNonProfit: Boolean,
        meetingCreated: String,
        meetingPlan: String
    }

    connect() {
        this.meeting_created = new Map(JSON.parse(this.meetingCreatedValue))
        for (const [key, value] of this.meeting_created) {
            this.meeting_created.set(key, new Date(this.meeting_created.get(key)));
        }
        if (this.hasDatefieldTarget && this.hasMeetingidTarget) {
            var created_date = this.meeting_created.get(parseInt(this.meetingidTarget.value));
            var date = new Date();
            date.setDate(created_date.getDate() + 92);
            this.datefieldTarget.dataset.flatpickrMaxDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        }
        this.participantsTarget.value = this.meetingPlanValue == 'mass' ? 1000 : 100;
        this.changePrice(null);
    }
    changeMeeting(e) {
        if (this.hasDatefieldTarget) {
            var created_date = this.meeting_created.get(parseInt(e.target.value));
            var date = new Date();
            date.setDate(created_date.getDate() + 92);
            this.datefieldTarget._flatpickr.set('maxDate', date);
        }
    }

    getMultiplyer() {
        if (this.meetingPlanValue == 'mass' || this.nonprofitTarget.checked) {
            return 300;
        }
        return 1000;
    }

    setNonProfit(e) {
        this.isNonProfitValue = e.target.checked;
        if (this.isNonProfitValue) {
            this.nonprofitPriceTargets.forEach(elm => elm.classList.remove('tw-hidden'));
            this.profitPriceTargets.forEach(elm => elm.classList.add('tw-hidden'));
        } else {
            this.nonprofitPriceTargets.forEach(elm => elm.classList.add('tw-hidden'));
            this.profitPriceTargets.forEach(elm => elm.classList.remove('tw-hidden'));
        }

        this.changePrice(null);
    }

    changePrice(e) {
        var participantsStep = this.meetingPlanValue == 'mass' ? 1000 : 50;

        if (this.participantsTarget.value % participantsStep != 0) {
            this.participantsTarget.value = Math.ceil(this.participantsTarget.value / participantsStep) * participantsStep;
        }
        var multiplyer = 1000;
        if (this.meetingPlanValue == 'mass' || this.isNonProfitValue) {
            multiplyer = 300;
        }
        multiplyer *= 1.1;
        this.amountTarget.innerHTML = Intl.NumberFormat().format(this.participantsTarget.value * multiplyer);
        this.amountFormTarget.value = this.participantsTarget.value * multiplyer;
        this.participantsCountTargets.forEach(element => element.innerHTML = Intl.NumberFormat().format(this.participantsTarget.value));
        if (this.isNonProfitValue) {
            this.nonprofitAmountTarget.innerHTML = Intl.NumberFormat().format(this.participantsTarget.value * 1000);
        }
    }

    notyMessage(msg, type) {
        new Noty({
            text: msg,
            type: type,
            layout: "topCenter",
            theme: "bootstrap-v4",
            progressBar: true,
            timeout: 3000,
        }).show();
    }

    submit(e) {
        if (this.hasMeetingPlanValue && this.meetingPlanValue == "mass") {
            const msPerDay = 8.64e7;
            const dateArray = this.datefieldTarget.value.split(" ~ ");
            let start_date = new Date(dateArray[0]);
            let end_date = new Date(dateArray[1]);
            start_date.setHours(12, 0, 0);
            end_date.setHours(12, 0, 0);

            if (!this.datefieldTarget.value.includes("~") || Math.round((end_date - start_date) / msPerDay) < 1) {
                this.notyMessage(
                    document.documentElement.lang === "ko" ? "실시간 행사인 경우 대량투표로 진행이 불가능합니다. 최소 2일이상으로 선택해주세요" : "In the case of a real-time event, it is not possible to proceed with mass voting. Please select at least 2 days",
                    "error"
                );
                e.preventDefault();
                e.stopPropagation();
            }
        }
        if (this.hasEmailTarget && this.emailTarget.value === '') {
            this.notyMessage(
                document.documentElement.lang === "ko" ? "이메일 입력은 필수 입니다." : "Email required",
                "error"
            );
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.hasObjectiveTarget && this.objectiveTarget.value === '') {
            this.notyMessage(
                document.documentElement.lang === "ko" ? "사용목적은 필수 입니다." : "Objective required",
                "error"
            );
            e.preventDefault();
            e.stopPropagation();
        }

        if (this.hasDatefieldTarget && this.datefieldTarget.value === '') {
            this.notyMessage(
                document.documentElement.lang === "ko" ? "날짜입력은 필수 입니다." : "Date required",
                "error"
            );
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.hasMeetingPlanValue && this.meetingPlanValue == "premium" &&
            !this.datefieldTarget.value.includes("~") && this.timefieldTarget.value === '') {
            this.notyMessage(
                document.documentElement.lang === "ko" ? "하루 행사 일경우 시간입력은 필수 입니다." : "Time required",
                "error"
            );
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.hasParticipantsTarget) {
            if (this.meetingPlanValue == "premium") {
                var participantsStep = 50;
                var minParticipants = 100;
                var planName = '행사/이벤트';
            } else {
                var participantsStep = 1000;
                var minParticipants = 1000;
                var planName = '온라인 전자 투표';
            }
            if (this.participantsTarget.value === '') {
                this.notyMessage(
                    document.documentElement.lang === "ko" ? "참여 예상 인원은 필수 입니다." : "Expected number of participants required",
                    "error"
                );
                e.preventDefault();
                e.stopPropagation();
            }



            if (this.participantsTarget.value < minParticipants) {
                this.notyMessage(
                    document.documentElement.lang === "ko" ? "인원은 " + minParticipants + "명이상  신청하실 수 있습니다." : "Expected number of participants required",
                    "error"
                );
                e.preventDefault();
                e.stopPropagation();
            }
            else if (this.participantsTarget.value % participantsStep != 0) {
                this.notyMessage(
                    document.documentElement.lang === "ko" ? planName + " 인원은 " + participantsStep + "명 단위로 신청하실 수 있습니다." : "Expected number of participants required",
                    "error"
                );
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }
}
