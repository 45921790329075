import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'

export default class extends Controller {
  static values = { keyword: Array, meetingId: Number, networkId: Number, userId: Number};
  static targets = ['keywordList','keywordButton'];

  connect() {
    this.networkChannel = new Channel('NetworkChannel', this, {
      meeting_id: this.meetingIdValue,
      network_id: this.networkIdValue
    });
  }

  add(payload) {
    let link = document.createElement('a')
    link.href = window.location.origin+'/network_keywords/'+payload.keyword_id+'/select'
    link.dataset.networkTarget = 'keywordButton'
    link.rel = 'nofollow'
    link.dataset.method='post'
    let btn = document.createElement('div')
    btn.classList.add('tw-bg-gray-200','tw-inline-block','tw-px-2', 'tw-py-1','tw-m-1', 'tw-text-gray-500', 'tw-font-medium')
    btn.innerText = payload.keyword_title
    link.append(btn)

    this.keywordListTarget.append(link)
  }

  select(payload) {
    this.keywordButtonTargets.forEach(elm=>{
      if(this.userIdValue== payload.user_id && elm.href == window.location.origin+'/network_keywords/'+payload.keyword_id+'/select'){
        if(elm.firstChild.classList.contains('tw-bg-blue-500')){
          elm.firstChild.classList.remove('tw-bg-blue-500','tw-text-white')
          elm.firstChild.classList.add('tw-bg-gray-200','tw-text-gray-500')
        }else{
          elm.firstChild.classList.remove('tw-bg-gray-200','tw-text-gray-500')
          elm.firstChild.classList.add('tw-bg-blue-500','tw-text-white')
        }
      }
    })
  }

}
