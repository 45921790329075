import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["expand", 'trigger', 'dropdownbutton']
    static values = { item: String }
    connect() {}

    expandRemoteController(event) {
        $(this.expandTarget).addClass("grow");
        $(this.dropdownbuttonTarget).removeClass("dropdown-open");
        $(this.dropdownbuttonTarget).addClass("dropdown-close");
    }
    shrinkRemoteController(event) {
        $(this.expandTarget).removeClass("grow");
        $(this.dropdownbuttonTarget).removeClass("dropdown-open");
        $(this.dropdownbuttonTarget).addClass("dropdown-close");
    }
    dropdownSessions(event) {
        $(this.dropdownbuttonTarget).toggleClass('dropdown-close');
        $(this.dropdownbuttonTarget).toggleClass('dropdown-open');
    }
}
