import { Controller } from "@hotwired/stimulus";
import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";

export default class extends Controller {
    static targets = ["form"];
    static values = { itemType: String, locale: String };

    connect() {}
    notyMessage(msg, type) {
        new Noty({
            text: msg,
            type: type,
            layout: "topCenter",
            theme: "bootstrap-v4",
            progressBar: true,
            timeout: 3000,
        }).show();
    }
    submit(event) {
        this.validateForm(this.formTarget);
        if (!this.isValid) {
            event.preventDefault();
        }
    }

    textType(itemType) {
        if (itemType == "cheer") return "응원 항목에";
        if (itemType == "poll") return "선택 항목에";
        if (itemType == "borda") return "항목에";
        if (itemType == "multi_choice_vote") return "항목에";
        return;
    }
    checkItemValid(itemType, field) {
        if (
            itemType == "cheer" ||
            itemType == "poll" ||
            itemType == "borda" ||
            itemType == "multi_choice_vote"
        ) {
            let onceFieldSelectors = 'input[require="once"]';
            let onceFields = this.formTarget.querySelectorAll(onceFieldSelectors);
            let count = 0;
            onceFields.forEach((field) => {
                if (field.value) count++;
            });

            if (count == 0) {
                this.notyMessage(
                    `${this.textType(itemType)} 하나라도 내용을 넣어주세요.`,
                    "error"
                );
                return false;
            }
        }
        return true;
    }
    validateForm() {
        this.isValid = true;

        let requiredFieldSelectors = 'input[require="true"]';
        let requiredFields = this.formTarget.querySelectorAll(
            requiredFieldSelectors
        );

        requiredFields.forEach((field) => {
            if (!field.disabled && !field.value.trim()) {
                field.focus();

                this.isValid = false;
                let label = field.previousSibling.previousSibling;
                if (label) {
                    this.notyMessage(
                        `${label.textContent.split("(")[0]}값이 없습니다`,
                        "error"
                    );
                    return;
                }
            }
            if (!this.checkItemValid(this.itemTypeValue, field)) {
                this.isValid = false;
                return;
            }
        });
    }
}
