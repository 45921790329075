import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';

export default class extends Controller {
    static values = { meetingId: Number,
        quizId: Number, questionId: Number}
    static targets = [ 'correct', 'all', 'ratio', 'userName', 'userImage', 'userAllPoint', 'userTime', 'rank', 'firstUserName', 'firstUserScore' ]
    connect(){
    }
    init(){
        this.correctTarget.textContent = 0
        this.allTarget.textContent = 0
        this.ratioTarget.textContent = 0
        this.rankTargets.forEach( (rank) =>{
            if( !rank.classList.contains('tw-hidden')){
                rank.classList.add('tw-hidden');
            }
        })
        this.userNameTargets.forEach( userName => {
            userName.textContent = ''
        })
        this.userTimeTargets.forEach( userTime => {
            userTime.textContent = '0s / + 0'
        })
        this.userImageTargets.forEach( userImage => {
            userImage.style.backgroundImage = ''
        })
    }
    fetch_result(){
        this.init();
        fetch(`/quizzes/${this.quizIdValue}/rank?question=${this.questionIdValue}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
            this.correctTarget.textContent = json.correct
            this.allTarget.textContent = json.all
            this.ratioTarget.textContent = json.ratio
            this.firstUserNameTarget.textContent = json.first_user_name
            this.firstUserScoreTarget.textContent =
                document.documentElement.lang === 'ko'
                ? `총 ${json.first_user_score}점`
                : `Total ${json.first_user_score}`

            json.users.forEach( (user, index) => {
                this.userNameTargets[index].textContent = user[0]
                this.userTimeTargets[index].textContent = `${user[2]}s`
                this.userAllPointTargets[index].textContent =
                    document.documentElement.lang === 'ko'
                    ? `/ 총 ${user[3]}점`
                    : `/ Total ${user[3]}`
                this.userImageTargets[index].style.backgroundImage = `url(${user[1]})`
                this.rankTargets[index].classList.remove('tw-hidden');
            })
        })

    }
}
