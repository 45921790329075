import { Controller } from '@hotwired/stimulus'
import Masony from 'masonry-layout'

export default class extends Controller {
    static targets = ['listMeetings', 'meeting']
    connect() {
        this.relayout();
    }
    relayout() {
        this.masonry = new Masonry(this.listMeetingsTarget, { itemSelector: '.archive-item', percentPosition: true })
    }
    load_image(evt) {
        this.relayout();
    }
    error_image(evt) {
        this.relayout();
    }
    load_window(evt) {
        this.relayout();
    }
}
