import { Controller } from "@hotwired/stimulus";
import Channel from "../channels/channel";

export default class extends Controller {
  static values = { meetingId: Number, itemId: Number}

  connect() {
      
    this.channel = new Channel("ReserveAheadReservationChannel", this, {
      meeting_id: this.meetingIdValue,
      stage_item_type: "ReserveAhead",
      reserveAheadId: this.itemIdValue,
    });
  }


  // 서버에서 reservation_update 액션이 호출될 때 실행되는 메서드
  reservation_update(payload) {
    console.log("reservation_update", payload);

    const { action, reserve_ahead_id, slot_id, previous_slot_id } = payload;

    // 현재 슬롯을 업데이트
    if (slot_id) {
      this.updateSlot(slot_id);
      this.updateDashboardSlot(slot_id);
    }

    // 이전 슬롯이 있는 경우, 이전 슬롯을 업데이트
    if (previous_slot_id && previous_slot_id !== slot_id) {
      this.updateSlot(previous_slot_id);
      this.updateDashboardSlot(previous_slot_id);
    }
  }

  // 특정 슬롯을 업데이트하는 메서드
  updateSlot(slotId) {
    const slotElement = document.getElementById(`slot-${slotId}`);
    if (!slotElement) return; // 요소가 존재하지 않으면 종료
    console.log("updateSlot", slotId, slotElement);
    // AJAX 요청을 통해 해당 슬롯을 다시 가져옴
    fetch(`/reserve_aheads/${this.itemIdValue}/slots/${slotId}`, {
      method: 'GET',
      headers: { "Accept": "text/vnd.turbo-stream.html" }
    })
      .then(response => response.text())
      .then(html => {
        console.log(html);
        if (slotElement.parentNode) {
          slotElement.outerHTML = html; // 부모 요소가 있는 경우에만 outerHTML을 업데이트
        } else {
          console.warn(`Parent node not found for slot-${slotId}, cannot replace outerHTML.`);
        }
      })
      .catch(error => console.error('Error fetching slot content:', error));
  }

  updateDashboardSlot(slotId) {
    const slotElement = document.getElementById(`slot-dashboard-${slotId}`);
    if (!slotElement) return; // 요소가 존재하지 않으면 종료
    console.log("updateDashboardSlot", slotId, slotElement);
    // AJAX 요청을 통해 해당 슬롯을 다시 가져옴
    fetch(`/reserve_aheads/${this.itemIdValue}/slots/${slotId}?dashboard=true`, { // 'dashboard' 파라미터를 추가
      method: 'GET',
      headers: { "Accept": "text/vnd.turbo-stream.html" }
    })
      .then(response => response.text())
      .then(html => {
        console.log(html);
        if (slotElement.parentNode) {
          slotElement.outerHTML = html; // 부모 요소가 있는 경우에만 outerHTML을 업데이트
        } else {
          console.warn(`Parent node not found for dashboard-slot-${slotId}, cannot replace outerHTML.`);
        }
      })
      .catch(error => console.error('Error fetching slot content:', error));
  }
  
}