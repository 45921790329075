import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['element'];
  static classes = ['css'];

  connect(){
    this.prevClickedEle = null;
  }

  toggleByOthers(e){
    if (this.prevClickedEle !== null && this.prevClickedEle === e.target) return;
    this.prevClickedEle = e.target;

    this.elementTarget.classList.toggle(this.cssClass);
  }

}
