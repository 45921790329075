import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['option']
    static values = { type: String }
    connect() {
    }
    uncheck_others(event) {
        if (this.typeValue === 'multi_select') return;
        this.optionTargets.forEach((target) => {
            if (event.target === target) return;
            target.checked = false;
        })
    }
}
