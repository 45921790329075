import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["play", "stop"];
  static values = { blind: Boolean, itemType: String, itemReady: Boolean, itemOpended: Boolean };

  connect() {}
  play(evt) {
    if (!this.blindValue) return;
    if (this.hasItemTypeValue && this.itemTypeValue == "Cheer") return;
    if (!this.itemReadyValue) {
      alert(
        document.documentElement.lang === "ko"
          ? "블라인드 타운홀은 한번 투표 중지하면 다시 시작하지 못합니다."
          : "Blind TownHall can't be restarted once the vote is stopped"
      );
      evt.preventDefault();
      evt.stopPropagation();
    }
  }
  stop(evt) {
    if (!this.blindValue) return;
    if (this.hasItemTypeValue && this.itemTypeValue == "Cheer") return;

    if (
      !confirm(
        document.documentElement.lang === "ko"
          ? "블라인드 타운홀은 한번 투표 중지하면 다시 시작하지 못합니다. 중지하시겠습니까?"
          : "Once you stop voting, you cannot restart the blind townhall. Are you sure you want to stop?"
      )
    ) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }
  show_dashboard(evt) {
    if (!this.blindValue) return;
    if (this.hasItemTypeValue && this.itemTypeValue == "Cheer") return;
    if (this.itemReadyValue || this.itemOpendedValue) {
      alert(
        document.documentElement.lang === "ko"
          ? "종료 후 확인 가능합니다.(응원 제외)"
          : " After this blind poll is completed, it shows the poll result on the dashboard."
      );
      evt.preventDefault();
    }
  }
}
