import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["imageButton", "imageBox", "imageTextButton"]
    static values = {
        status: Boolean
    }
    connect() {}

    toggleImage(e) {
        if (this.statusValue === false) return;
        e.stopPropagation();
        this.imageBoxTarget.classList.toggle("no-display");
        
        if (this.hasImageButtonTarget === true) {
            if (this.imageBoxTarget.classList.contains("no-display")) {
                this.imageButtonTarget.src = this.imageButtonTarget.dataset.moreIcon;
            } else {
                this.imageButtonTarget.src = this.imageButtonTarget.dataset.lessIcon;
            }
        }
        if (this.hasImageTextButtonTarget === true) {
            if (this.imageBoxTarget.classList.contains("no-display")) {
                this.imageTextButtonTarget.innerHTML = "<i class='fa fa-chevron-down'></i> 열기";
            } else {
                this.imageTextButtonTarget.innerHTML = "<i class='fa fa-chevron-up'></i> 접기";
            }
        }
    }
    bigsmallImage(e) {
        if (this.statusValue === false) return;
        e.stopPropagation();
        e.target.classList.toggle("small-img");
        if (e.target.classList.contains("small-img") === false) {
            e.target.classList.add("big-img");
        } else {
            e.target.classList.remove("big-img");
        }
    }
}
