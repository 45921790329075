import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['rank', 'correct', 'total', 'ratio', 'point', 'congrat', 'ranktitle', 'allAttendance']
    static values = { id: Number, user: Number }
    connect() {
    }
    fetch_results(){
        fetch(`/quizzes/${this.idValue}/final_user_rank?user=${this.userValue}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.allAttendanceTarget.textContent =
          document.documentElement.lang === 'ko'
          ? `${json.all}명`
          : `${json.all}`
          this.totalTarget.textContent = json.total
          this.correctTarget.textContent = json.correct
          this.ratioTarget.textContent = json.ratio
          this.rankTarget.textContent = json.rank
          this.pointTarget.textContent = json.point
          if(json.rank < 10 && json.rank >= 1){
            this.congratTarget.classList.remove('tw-hidden')
          }
          if(json.rank > 0){
            this.ranktitleTarget.classList.remove('tw-hidden')
          }
        })

    }
}
