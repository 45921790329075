import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'

import 'node-remove-polyfill';

import Masony from 'masonry-layout'

export default class extends Controller {
    static targets = ['commentsList', 'comment', 'upvote', 'commentsCount', 'emptyAlert', 'more']
    connect() {
        if (this.hasCommentsListTarget) {
            this.masonry = new Masonry(`.${this.commentsListTarget.classList[0]}`, { itemSelector: '.comment-item', percentPosition: true })
        }

        if (this.data.get('stageItemType') === 'Suggestion') {
            this.suggentionChannel = new Channel('SuggestionDashboardChannel', this, { meeting_id: this.data.get('meetingId'), stage_item_id: this.data.get('stageItemId') })
        }

        this.commentChannel = new Channel('CommentDashboardChannel', this, { meeting_id: this.data.get('meetingId'), stage_item_type: this.data.get('stageItemType'), stage_item_id: this.data.get('stageItemId') })
    }
    relayout(newElement, removeElement) {
        if(this.masonry === 'undefined')
            this.masonry = new Masonry(`.${this.commentsListTarget.classList[0]}`, { itemSelector: '.comment-item', percentPosition: true })

        if(newElement !== null)
            this.masonry.prepended(newElement);

        if(removeElement !== null)
            this.masonry.remove(removeElement);
        this.masonry.layout();
    }
    ready(event){
        this.relayout(null, null);
    }

    morePage(event){
        this.relayout(null, null);
    }

    reload(payload) {
        window.location.reload();
    }
    created(payload) {
        if (this.hasEmptyAlertTarget) {
            this.emptyAlertTarget.remove();
        }
        this.safeDoms(payload.partial).reverse().forEach(currentElement => {
            this.commentsListTarget.insertBefore(currentElement, this.commentsListTarget.firstElementChild)
            this.relayout(currentElement, null);

        })
        // window.location.reload();

        this.updateCommentsCount(payload)

        const newComment = this.commentsListTarget.firstElementChild;
        const images = newComment.querySelectorAll('img');

        if (images.length > 0) {
            const promises = Array.from(images).map(img =>
                new Promise(resolve => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.addEventListener('load', resolve, { once: true });
                    }
                })
            );

            Promise.all(promises).then(() => {
                this.masonry.layout();
            });
        }
    }

    updated(payload) {

        this.mapElements(this.commentTargets, payload.commentId, currentElement => {
            this.safeDoms(payload.partial).reverse().forEach(newElement => {
                this.commentsListTarget.insertBefore(newElement, currentElement)
                this.relayout(newElement, null)
            })
            this.commentsListTarget.removeChild(currentElement)
            this.relayout(null, currentElement)
        })


        this.updateCommentsCount(payload)
    }

    destroyed(payload) {
        this.mapElements(this.commentTargets, payload.commentId, currentElement => {
            currentElement.parentNode.removeChild(currentElement)
            this.relayout(null, currentElement);

        })

        this.updateCommentsCount(payload)
    }

    upvoted(payload) {
        this.mapElements(this.upvoteTargets, payload.commentId, currentElement => {
            currentElement.textContent = payload.upvotesCount
        })
        this.updateCommentsCount(payload)
    }

    updateCommentsCount(payload) {
        this.commentsCountTarget.textContent = payload.commentsCount
    }

    safeDoms(partial) {
        if (partial) {
            const temp = document.createElement('div')
            temp.innerHTML = partial;
            return Array.from(temp.children)
        }
    }

    mapElements(targets, commentId, callback) {
        return targets.filter(currentElement => {
            let currentCommentId = currentElement.dataset.commentId
            return commentId === +currentCommentId
        }).forEach(callback.bind(this))
    }

}
