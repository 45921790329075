import consumer from './consumer'

class Channel {
    constructor(channel, listener, options = {}) {
        this.subscription = consumer.subscriptions.create({ channel, ...options }, { received: (data) => this.received(data) })

        this.listener = listener
    }

    received(data) {
        if (this.listener[data.action]) {
            this.listener[data.action].bind(this.listener)(data)
        }
    }

    send(data){
        this.subscription.send(data);
    }

    perform(action, data){
        this.subscription.perform(action, data);
    }

    unsubscribe() {
        consumer.subscriptions.remove(this.subscription);
    }
}

export default Channel
