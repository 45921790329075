import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = ['check']
    connect() {}
    submit(evt) {
        if (!this.hasCheckTarget) return;
        if (this.checkTarget.checked){
            if (!confirm("블라인드 타운홀을 선택하셨습니다. 블라인드 타운홀은 참여자 투표 이력을 확인할 수 없으며 이후 변경이 불가능합니다. 저장하시겠습니까?")) {
                evt.preventDefault();
                evt.stopPropagation();
            }
        }
    }
}
