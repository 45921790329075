import { Controller } from "@hotwired/stimulus";
import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";

export default class extends Controller {
  static targets = ["submitButton"];

  initialize() {
    this.selectedUrl = null;
    this.selectedMethod = null;
    this.selectedButton = null;
  }
  notyMessage(msg, type) {
    new Noty({
      text: msg,
      type: type,
      layout: "topRight",
      theme: "bootstrap-v4",
      progressBar: true,
      timeout: 3000,
    }).show();
  }

  selectSlot(event) {
    event.preventDefault();

    this.selectedUrl = event.params.url;
    this.selectedMethod = event.params.method;
    this.selectedButton = event.currentTarget;

    this.clearPreviousSelection();

    this.updateButton();

    this.updateButtonState();
  }

  clearPreviousSelection() {
    const previouslySelectedButton = document.querySelector(".slot-selected");
    if (previouslySelectedButton) {
      previouslySelectedButton.classList.remove("slot-selected");
      previouslySelectedButton.classList.replace(
        "tw-border-mainPurple",
        "tw-border-gray-100"
      );

      previouslySelectedButton.innerHTML =
        previouslySelectedButton.dataset.position;
    }
  }

  updateButton() {
    if (this.selectedButton) {
      this.selectedButton.classList.replace(
        "tw-border-gray-100",
        "tw-border-mainPurple"
      );
      this.selectedButton.classList.add("slot-selected");
      this.selectedButton.innerHTML = '<i class="fa fa-check"></i>';
    }
  }

  updateButtonState() {
    if (!this.hasSubmitButtonTarget) {
      return;
    }
    if (this.selectedUrl && this.selectedMethod) {
      this.submitButtonTarget.removeAttribute("disabled");
      this.submitButtonTarget.classList.add("tw-cusor-pointer");
      this.submitButtonTarget.classList.add("tw-bg-mainOrange");
      this.submitButtonTarget.classList.remove("tw-cursor-not-allowed");
      this.submitButtonTarget.classList.remove("tw-bg-gray-400");
    } else {
      this.submitButtonTarget.setAttribute("disabled", "true");
      this.submitButtonTarget.classList.remove("tw-cusor-pointer");

      this.submitButtonTarget.classList.remove("tw-bg-mainOrange");
      this.submitButtonTarget.classList.add("tw-cursor-not-allowed");

      this.submitButtonTarget.classList.add("tw-bg-gray-400");
    }
  }

  submitReservation() {
    if (this.selectedUrl && this.selectedMethod) {
      this.sendRequest(this.selectedUrl, this.selectedMethod);
    } else {
      alert("먼저 자리를 선택해 주세요.");
    }
  }

  sendRequest(url, method) {
    fetch(url, {
      method: method,
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          this.selectedUrl = null;
          this.selectedMethod = null;
          this.updateButtonState();
          return response.text();
        } else {
          throw new Error("네트워크 응답이 올바르지 않습니다.");
        }
      })
      .then((html) => {
        if (url.includes("cancel")) {
          this.notyMessage("예약이 취소되었습니다.", "success");
        } else if (url.includes("wait")) {
          this.notyMessage("대기 처리가 되었습니다.", "success");
        } else {
          this.notyMessage("신청 완료되었습니다.", "success");
        }
        this.selectedUrl = null;
        this.selectedMethod = null;
        this.updateButtonState();
      })
      .catch((error) => {
        console.error("Error:", error);
        this.notyMessage("예약 처리 중 오류가 발생했습니다.", "error");
      });
  }
}
