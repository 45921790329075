import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['element', 'desc'];
  static classes = ['checked', 'unchecked'];

  connect(){
    this.styled(null);
  }
  styled(e){
    if(e !== null){
        e.target.parentNode.querySelector('input[type=radio]').checked = true;
    }
    this.elementTargets.forEach(element => {

        if(element.parentNode.querySelector('input[type').checked){
            element.classList.replace(this.uncheckedClass, this.checkedClass);
            if(e !== null && this.hasDescTarget){
                this.descTarget.textContent = `* ${e.params.text}`;
            }
        }
        else{
            element.classList.replace(this.checkedClass, this.uncheckedClass);
        }
    });
  }
}
