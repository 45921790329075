import { Controller } from '@hotwired/stimulus'
import { Swiper, Navigation, Pagination, SwiperOptions, Autoplay } from 'swiper'
// @ts-ignore
import 'swiper/swiper-bundle.min.css';

export default class extends Controller {

    static values = { options: Object }

    connect() {
        var initslide = this.return_random_index();
        initslide = 0; // for event
        this.swiper = new Swiper(this.element, {
            modules: [Navigation, Pagination, Autoplay],
            ...this.optionsValue,
            initialSlide: initslide,
        })
        this.swiper.on('click', this.click_slide);
    }
    click_slide(swiper, evt) {
        if (!("link" in swiper.clickedSlide.dataset)) return;
        if (evt.path[0].tagName != 'A')
            window.open(swiper.clickedSlide.dataset.link, '_blank');
    }

    return_random_index() {
        var r_index = Math.floor(Math.random() * (document.querySelectorAll(".swiper-slide").length));
        return parseInt(r_index);
    }

    disconnect() {
        this.swiper.destroy()
        this.swiper = undefined
    }

    get defaultOptions() {
        return {}
    }
}
