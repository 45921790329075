import { Controller } from '@hotwired/stimulus'
import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";
export default class extends Controller {
    static targets = [ 'eventDate', 'eventTime', 'title' ]
    connect() {
    }
    checkvalidate(evt){
        let strWarning = '';
        if ( this.eventDateTarget.value === '')
        {
            strWarning =  document.documentElement.lang === "ko"
            ? "행사 날짜를 넣어주세요."
            : "Please insert event date."
        }
        else if( !this.eventDateTarget.value.includes("~") && this.eventTimeTarget.value === '' )
        {
            strWarning =  document.documentElement.lang === "ko"
            ? "행사 시간을 넣어주세요."
            : "Please insert event time."
        }
        if ( this.titleTarget.value === '' )
        {
            strWarning = document.documentElement.lang === "ko"
            ? "제목을 넣어주세요."
            : "Please insert title."
        }

        if ( strWarning != '')
        {
            new Noty({
                text: strWarning,
                type: "error",
                theme: "bootstrap-v4",
                layout: "center",
                timeout: 3000,
              }).show();
            evt.preventDefault();
            evt.stopPropagation();
        }

    }

}
