import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['element', 'hide'];

  connect(){
  }

  replace(e){
    if(e.params.text.length === 0 && this.hasHideTarget){
      this.hideTarget.classList.add('tw-hidden')
    }
    else if(this.hasHideTarget){
      this.hideTarget.classList.remove('tw-hidden')
      this.elementTarget.textContent = e.params.text
    }
  }

}
