import { Controller } from '@hotwired/stimulus'
import Noty from 'noty'
import 'noty/src/noty.scss'
import 'noty/src/themes/bootstrap-v4.scss'
export default class extends Controller {
    static targets = ['userInfoPanel', 'checkbox', 'element', 'datatype', 'selectlistInput', 'type', 'add']
    static values = { contactCount: Number }

    connect() {
        this.cardOnOff = false;
        if (this.contactCountValue > 0) {
            this.cardOnOff = true;
            this.checkboxTarget.checked = true;
        }
        this.showPanel(this.cardOnOff);
        if (this.hasTypeTarget) {
            this.typeTargets.forEach(element => {
                this.toggleSelectList(element);
            });
        }
    }
    notyMessage(msg, type) {
        new Noty({
            text: msg,
            type: type,
            layout: 'topCenter',
            theme: 'bootstrap-v4',
            progressBar: true,
            timeout: 3000
        }).show();
    }
    countElements() {
        var count = 0;
        this.elementTargets.forEach(element => {
            if (element.style.display !== 'none') count++;
        });
        return count;
    }
    toggleSelectList(ele) {
        if (ele.value === "8" || ele.value === "9")
            ele.nextElementSibling.style.display = 'inline-block';
        else
            ele.nextElementSibling.style.display = 'none';
    }
    changeType(evt) {
        this.toggleSelectList(evt.srcElement);

    }
    togglePanel(evt) {
        if (evt.srcElement.value !== 'user_extra_info') return;

        if (this.hasElementTarget && this.countElements() > 0) {
            this.notyMessage("사용자 정보 입력 항목을 모두 삭제해야 합니다.", 'error');
            this.checkboxTarget.checked = true;
            return;
        }
        this.cardOnOff = !this.cardOnOff;
        this.showPanel(this.cardOnOff);
    }

    showPanel(on) {
        if (on) {
            if (this.countElements() == 0) {
                this.addTarget.click();
            }
            this.userInfoPanelTarget.style.display = 'inline-block';
        } else this.userInfoPanelTarget.style.display = 'none';
    }
}
