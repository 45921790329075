import { Controller } from 'stimulus'

export default class extends Controller {
    static values = { updateUrl: String }
    connect() {
    }
    getMetaValue(key){
        const token = document.getElementsByName(
            key
            )[0].content;
        return token;
    }
    toggle_random(e){

        let formData = new FormData()
        formData.append("stage[random]", e.srcElement.checked);

        fetch(this.updateUrlValue, {
            body: formData,
            method: 'PATCH',
            dataType: 'script',
            credentials: "include",
            headers: {
                    "X-CSRF-Token": this.getMetaValue("csrf-token")
             },
      }).then(response => response.json())
      .then(result => {
      e.srcElement.checked = result.stage.random;}
      )
    }
}
