import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    locale: String,
  };

  connect() {
    // console.log("introduction controller connect");
  }

  voteChangeBtnListener(e) {
    const showClass =
      "tw-inline-block tw-rounded-full tw-px-4 tw-py-2 tw-font-bold tw-cursor-default tw-bg-mainPurple tw-text-white vote-btn";
    const hideClass =
      "tw-inline-block tw-rounded-full tw-px-4 tw-py-2 tw-font-bold tw-cursor-pointer tw-bg-gray-200 tw-text-gray-800 hover:tw-bg-mainPurple hover:tw-bg-opacity-10 hover:tw-text-mainPurple-dark tw-transition vote-btn";
    const currentName = e.target.innerText;
    const voteChangeBtnClass = document.getElementsByClassName("vote-btn");

    Array.from(voteChangeBtnClass).forEach((target, index) => {
      if (currentName === target.innerHTML) {
        target.className = showClass;
        this.voteDescChange(index);
      } else {
        target.className = hideClass;
      }
    });
  }

  voteDescChange(voteChangeBtnIndex) {
    const voteDescClass = document.getElementsByClassName("vote-desc");
    Array.from(voteDescClass).forEach((target, index) => {
      if (voteChangeBtnIndex === index) {
        target.classList.remove("tw-hidden");
      } else {
        target.classList.add("tw-hidden");
      }
    });
  }
}
