import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['rank', 'correctAnswer', 'correctAnswerRate', 'loading']
    static values = { id: Number, user: Number, count: Number }
    connect() {
    }
    fetch_results(){

        fetch(`/quizzes/${this.idValue}/result?user=${this.userValue}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
            this.rankTarget.textContent = json.rank
            this.correctAnswerTarget.textContent = json.correct_answer_count
            this.correctAnswerRateTarget.textContent = ((json.correct_answer_count) * 100 / (this.countValue)).toFixed(2)
            this.loadingTarget.classList.add('tw-hidden')
        })

    }
}
