import { Controller } from '@hotwired/stimulus'
import * as Turbo from "@hotwired/turbo"
import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";

export default class extends Controller {
    static targets = [ "type", "info", "row", "step" ];
    static values = { href: String }
    connect() {
    }
    getMetaValue(key){
        const token = document.getElementsByName(
            key
            )[0].content;
        return token;

    }
    notyMessage(msg, type) {
        new Noty({
          text: msg,
          type: type,
          layout: "topRight",
          theme: "bootstrap-v4",
          progressBar: true,
          timeout: 3000,
        }).show();
      }
    saveJudgeType2Meeting(e){

        if(this.rowTargets.length > 0)
        {
            if (
                !confirm(
                  document.documentElement.lang === "ko"
                    ? "가중치 투표방식을 변경하면 이전에 생성한 심사위원 그룹이 삭제됩니다. 변경하시겠습니까?"
                    : "Changing the weighted voting method will delete the previously created judge group. Are you sure you want to change it?"
                )
            )
            {
                e.preventDefault();
                e.stopPropagation();
                return;
            }
        }

        let formData = new FormData()
        formData.append("meeting[judge_type]", e.target.value);
        formData.append("method", 'patch');
        fetch(this.hrefValue, {
            body: formData,
            method: 'PATCH',
            dataType: 'script',
            credentials: "include",
            headers: {
                    "X-CSRF-Token" : this.getMetaValue("csrf-token")
             },
      }).then(response =>{
        if(response.status === 200)
        {
            let type_title;
            if(e.target.value === 'vote_weight')
            {
                type_title = document.documentElement.lang === "ko" ? '표' : 'votes';
                this.stepTarget.setAttribute("step", "any");
            }
            else if(e.target.value === 'percentage')
            {
                type_title = '%';
                this.stepTarget.setAttribute("step", "1");

            }
            this.typeTargets.forEach(function(target){
                target.innerHTML = type_title;
            })
            this.notyMessage(
                document.documentElement.lang === "ko" ? '저장되었습니다' : 'has been saved',
                'success')
            if(this.rowTargets.length === 0) return;

            let node = this.rowTargets[0].parentNode
            if(node){
                this.rowTargets.forEach(element => node.removeChild(element))
            }
        }}
      );

    }
}
