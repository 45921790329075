import { Controller } from "@hotwired/stimulus";

import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    locale: String,
  };
  connect() {
    this.isValid = true;
  }

  submit(event) {
    this.validateForm(this.formTarget);
    if (!this.isValid) {
      event.preventDefault();
    }
  }
  notyMessage(msg, type) {
    new Noty({
      text: msg,
      type: type,
      layout: "topCenter",
      theme: "bootstrap-v4",
      progressBar: true,
      timeout: 3000,
    }).show();
  }
  getType(field) {
    let label = field.previousSibling.previousSibling;
    if (label) {
      // console.log("field", label.textContent.split("(")[0]);
      return label.textContent.split("(")[0];
    }
    return;
  }
  checkValid(field) {
    var pattern_num = /[0-9]/; // 숫자
    var pattern_eng = /[a-zA-Z]/; // 문자
    var pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
    var pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
    var pattern_kor_consonant_vowel = /[ㄱ-ㅎ|ㅏ-ㅣ]/;
    var pattern_phone_hypen = /^\d{3}-\d{3,4}-\d{4}$/;
    var pattern_phone_number = /^\d{3}\d{3,4}\d{4}$/;

    if (this.getType(field) == "이름") {
      if (
        !(
          !pattern_num.test(field.value) &&
          !pattern_spc.test(field.value) &&
          !pattern_kor_consonant_vowel.test(field.value) &&
          (pattern_eng.test(field.value) || pattern_kor.test(field.value)) &&
          field.value.length >= 2
        )
      ) {
        this.notyMessage(
          "이름은 한글, 영문 두자 이상 입력해야합니다. 한글은 자음,모음만 쓰지 않습니다.",
          "error"
        );
        return false;
      }
    }
    if (this.getType(field) == "닉네임") {
      if (
        !(
          !pattern_spc.test(field.value) &&
          ((pattern_num.test(field.value) && field.value.length >= 4) ||
            ((pattern_eng.test(field.value) || pattern_kor.test(field.value)) &&
              field.value.length >= 1))
        )
      ) {
        this.notyMessage(
          "닉네임은 한글, 영문 한글자 이상, 숫자 4글자이상 입력해야합니다.",
          "error"
        );
        return false;
      }
    }
    if (this.getType(field) == "휴대폰 번호") {
      if (
        !pattern_phone_hypen.test(field.value) &&
        !pattern_phone_number.test(field.value)
      ) {
        this.notyMessage("전화번호는 숫자 10~11자로 입력해야합니다", "error");
        return false;
      }
    }
    return true;
  }
  validateForm() {
    this.isValid = true;

    let requiredFieldSelectors =
      'input[required="required"], input[require="true"], select[require="true"]';
    let requiredFields = this.formTarget.querySelectorAll(
      requiredFieldSelectors
    );

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();

        this.isValid = false;
        let label = field.previousSibling.previousSibling;
        if (label) {
          if (this.localeValue === "en") {
            this.notyMessage(
              `${this.getType(field).replace("*", "")} is require`,
              "error"
            );
            return;
          }
          this.notyMessage(`${this.getType(field)}값이 없습니다`, "error");
          return;
        }
      }
      if (!this.checkValid(field)) {
        this.isValid = false;
        return;
      }
    });
  }
}
