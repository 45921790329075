import { Controller } from "@hotwired/stimulus";
import Channel from "../channels/channel";
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
import {
  Chart,
  ArcElement,
  PieController,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

export default class extends Controller {
  static targets = [
    "candidate",
    "canvas",
    "choices",
    "title",
    "total",
    "panel",
    "userpanel",
  ];
  static values = {
    meetingId: Number,
    candidateSeq: Number,
    itemId: Number,
    candidateId: Number,
    url: String,
    final: String,
    openclose: Boolean,
    active: Boolean,
    userDashboard: Boolean,
    locale: String,
  };
  initialize() {
    Chart.register(ArcElement, PieController, Legend, Title, Tooltip, SubTitle);
    Chart.defaults.elements.arc.borderWidth = 0;
    this.colorset = [
      "#D82B2B",
      "#FB923C",
      "#F1DE2C",
      "#98D72C",
      "#28C58B",
      "#0BA8EE",
      "#1760FF",
      "#3800FF",
      "#6C08EB",
      "#AF07A8",
    ];
  }

  retrieveData() {
    let choices = this.choicesTarget.querySelectorAll(".choice-num");
    this.dataset = Array();
    this.datasetTitle = Array();
    this.dataColorSet = Array();
    choices.forEach((choice) => {
      this.dataColorSet.push(
        this.colorset[choice.dataset.index % this.colorset.length]
      );
      this.datasetTitle.push(
        choice.getElementsByClassName("choice-title")[0].textContent.trim()
      );
      this.dataset.push(
        parseInt(
          choice.getElementsByClassName("choice-count")[0].textContent.trim()
        )
      );
    });
  }

  createPieChart() {
    const noDataText =
      document.documentElement.lang === "ko" ? "데이터 없음" : "No Data";
    const hideDataText =
      document.documentElement.lang === "ko" ? "비공개" : "Private";
    if (this.pieChart) {
      this.updateCanvas();
      return;
    }
    var fontColor = "#ffffff";
    var fontSize = "45px";
    if (this.userDashboardValue) {
      fontColor = "#000000";
      fontSize = "16px";
    }

    let baseText = noDataText;
    if (this.opencloseValue === false)
      baseText = hideDataText;
    this.pieChart = new Chart(this.canvasTarget, {
      type: "pie",
      responsive: true,
      maintainAspectRatio: false,
      data: {
        lables: this.datasetTitle,
        datasets: [
          {
            backgroundColor: this.dataColorSet,
            data: this.dataset,
            borderColor: "#181818",
            hoverOffset: 0,
          },
        ],
      },
      options: {
        title: {
          display: false,
        },
      },
      plugins: [
        {
          afterDraw: function (chart) {

              let sum = 0;
              for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
                sum += parseInt(chart.data.datasets[0].data[i]);
              }
              // No data is present
              var ctx = chart.ctx;
              var width = chart.width;
              var height = chart.height;
              if( isNaN(sum)){
                chart.clear();
                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = fontColor;
                ctx.fontSize = fontSize;
                ctx.font = `${ctx.fontSize} \"Helvetica Neue\", Helvetica, Arial, sans-serif`;
                ctx.fillText(hideDataText, width / 2, height / 2);
                ctx.restore();
              }
              else if (sum === 0 ) {
                chart.clear();
                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = fontColor;
                ctx.fontSize = fontSize;
                ctx.font = `${ctx.fontSize} \"Helvetica Neue\", Helvetica, Arial, sans-serif`;
                ctx.fillText(noDataText, width / 2, height / 2);
                ctx.restore();
              }

          },
        },
      ],
    });
  }
  updateCanvas() {
    this.pieChart.data = {
      lables: this.datasetTitle,
      datasets: [
        {
          backgroundColor: this.dataColorSet,
          data: this.dataset,
          borderColor: "#181818",
          hoverOffset: 0,
        },
      ],
    };
    this.pieChart.update();
  }
  retrieveJsonChoices(choices) {
    this.dataset = Array();
    this.datasetTitle = Array();
    choices.forEach((choice) => {
      let targetDomElement = this.choicesTarget.querySelector(
        `.choice-${choice.choice_id}`
      );
      targetDomElement.querySelector(".choice-title").textContent =
        choice.choice_title;
      this.datasetTitle.push(choice.choice_title);

      let choice_count = choice.count;

      targetDomElement.querySelector(".choice-count").textContent = this.opencloseValue === true ? choice_count :
        document.documentElement.lang === "ko" ? "비공개" : "Private";
      this.dataset.push(this.opencloseValue === true ? parseInt(choice_count) :
        document.documentElement.lang === "ko" ? "비공개" : "Private");

    });

  }
  updateCandidate(json) {
    json.candidates.forEach((candidate) => {
      if (candidate.id === this.candidateIdValue) {
        if(this.opencloseValue !== candidate.opened){
          this.opencloseValue = candidate.opened;

          window.location.reload();
        }
        this.opencloseValue = candidate.opened;

        if(this.hasCandidateTarget){
          if (candidate.opened){
            this.candidateTarget.classList.remove('tw-opacity-50');
          }
          else{
            this.candidateTarget.classList.add('tw-opacity-50');
          }
        }

        this.titleTarget.textContent = `${this.candidateSeqValue}. ${candidate.title}`;

        this.retrieveJsonChoices(candidate.choice_counts);
        this.totalTarget.textContent = candidate.total_counts;
        this.updateCanvas();
      }
    });
  }

  connect() {
    this.channel = new Channel("MultiChoiceVoteDashboardChannel", this, {
      meeting_id: this.meetingIdValue,
      stage_item_type: "MultiChoiceVote",
      multi_choice_vote_id: this.itemIdValue,
    });
    this.retrieveData();
    this.createPieChart();
  }
  rating(payload) {

    if (this.opencloseValue === false) return;
    if (payload.multi_choice_vote_id !== this.itemIdValue || payload.candidate_id !== this.candidateIdValue) return;

    fetch(this.urlValue)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.updateCandidate(json);
      });
  }
  fatal(payload) {
    console.log("fatal");
    console.log(payload);
    location.href = "/";
  }
  error(payload) {
    new Noty({
      text: data.message,
      type: "error",
      theme: "bootstrap-v4",
      layout: "topRight",
      timeout: 3000,
    }).show();
  }
  reload(payload) {
    window.location.reload();
  }
  updateOpenClose(json) {
    json.candidates.forEach((candidate) => {
      if (candidate.id === this.candidateIdValue) {
        this.activeValue = candidate.active ? true : false;
        this.opencloseValue = candidate.opened;

        if (candidate.active || this.openCloseValue != candidate.opened) {
          this.updateCandidate(json);
        }
      }
    });
  }

  reload_candidate(payload) {
    if (payload.multi_choice_vote_id != this.itemIdValue) return;
    fetch(this.urlValue)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.updateOpenClose(json);
      });
  }
}
