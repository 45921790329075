import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';

export default class extends Controller {
    static values = {
        quizId: Number, questionId: Number, user: Number}
    static targets = ['rank', 'correct', 'all', 'ratio', 'sorry', 'contrat', 'score']
    connect(){
    }
    fetch_result(){
        this.contratTarget.classList.add('tw-hidden')
        this.sorryTarget.classList.add('tw-hidden')

        fetch(`/quizzes/${this.quizIdValue}/user_rank?question=${this.questionIdValue}&user=${this.userValue}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
            this.correctTarget.textContent = json.correct
            this.allTarget.textContent = json.all
            this.ratioTarget.textContent = json.ratio
            this.scoreTarget.textContent = json.score
            this.rankTarget.textContent = json.rank
            if (json.rank >= 1 )
            {
                this.contratTarget.classList.remove('tw-hidden')
            }
            if (json.rank == 0)
            {
                this.sorryTarget.classList.remove('tw-hidden')
            }
        })
    }
}
