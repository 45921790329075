import { Controller } from '@hotwired/stimulus'
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
export default class extends Controller {

    static values = { quizId: Number, meetingStatus: Boolean, quizStatus: String, answers: Number, limitTime: Number }
    static targets = [ 'slider', 'timer', 'digit', 'warning', 'play', 'on', 'off', 'readyMsg']

    connect(){
        if(this.hasLimitTimeValue === false){
            this.limitTimeValue = 30;
        }
        this.time = this.limitTimeValue;
        this.timer = -1;
    }
    warning(event){
        event.preventDefault();
        this.warningTarget.classList.remove('tw-hidden');
     }

    backdropClick(event) {
        event.target === this.warningTarget && this.close(event)
    }
    play(){
        this.sliderController = this.application.getControllerForElementAndIdentifier(this.sliderTarget, 'quiz-dashboard-slider');
        this.sliderController.setTimerController(this);
        this.sliderController.start();
        if(this.hasOnTarget){
            this.onTarget.classList.replace('tw-opacity-0','tw-opacity-100');
            this.readyMsgTarget.textContent =
                document.documentElement.lang === 'ko'
                ? '퀴즈가 시작됩니다. 바로시작하려면 오른쪽에 있는 화살표를 눌러주세요'
                : 'The quiz begins. Click the arrow to the right to start right away';
        }
    }
    registerController(e){
        if(this.meetingStatusValue === false){
            new Noty({
                text:
                document.documentElement.lang === 'ko'
                ? '타운홀이 종료되었습니다.'
                : 'Townhall is closed.'
                ,
                type: "error",
                theme: "bootstrap-v4",
                layout: "center",
                timeout: 3000,
              }).show();
            return;
        }
        if(this.quizStatusValue !== 'opened'){
            new Noty({
                text:
                document.documentElement.lang === 'ko'
                ? '시작 상태가 아닙니다 시작을 하고 플레이 버튼을 눌러주세요.'
                : 'It is not in the starting state. Please start and press the play button.',
                type: "error",
                theme: "bootstrap-v4",
                layout: "center",
                timeout: 3000,
              }).show();
            return;
        }
        if(this.timer !== -1){
            return;
        }
        if(this.warningTarget.classList.contains('tw-hidden')){
            if(this.answersValue > 0){
                this.warning(e);
                return;
            }
            else {
                fetch(`/quizzes/${this.quizIdValue}/final_rank`)
                .then((response) => {
                  return response.json();
                })
                .then((json) => {
                    if (json.attend > 0){
                        this.warning(e);
                        return;
                    }
                    else{
                        this.play();
                    }
                })
            }
        }
        else{
            this.play();
        }

    }
    updateInterval(){
        this.time -= 1;
        if(this.time <= 0){
            this.stopTimer();

            if (this.sliderController.currentAction() === 'start'){
                this.nextSlide()
            }
            else if(this.sliderController.currentAction() === 'open'){
                this.sliderController.gotoNext(null);
            }
            return;
        }
        else{
            this.digitTarget.innerText = this.time;
        }
    }
    nextSlide(){
        if(this.sliderController.currentAction() === 'final_rank'){
            this.stopTimer();
            return;
        }
        this.sliderController.gotoNext(null);
        if(this.timer !== null){
            this.startTimer();
        }

    }
    stopTimer(){
        if(this.hasOnTarget){
            this.onTarget.classList.replace('tw-opacity-100','tw-opacity-0');
            this.readyMsgTarget.textContent =
                document.documentElement.lang === 'ko'
                ? '시작버튼을 클릭하면 퀴즈가 시작됩니다.'
                : 'Click the Start button to start the quiz.';
        }
        if(this.hasWarningTarget && !this.warningTarget.classList.contains('tw-hidden')){
            this.warningTarget.classList.add('tw-hidden');
        }

        this.timerTarget.classList.add('tw-hidden')
        clearInterval(this.timer);
        this.timer = -1;
    }
    startTimer(){
        if (this.timer !== -1){
            this.stopTimer();
        }
        this.digitTarget.innerText = this.time = this.limitTimeValue;
        this.timerTarget.classList.remove('tw-hidden')

        this.timer = setInterval(() => this.updateInterval(), 1000);
    }
}
