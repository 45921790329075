import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['select', 'subselect']
    static values = { administration: Object, region: String, slug: String, selectGu: String, selectDong: String }

    connect() {
        if (this.hasSelectTarget) {
            if (this.selectTarget.dataset.type == 'region') {
                this.regionValue = this.selectTarget.dataset.region
                this.slugValue = this.selectTarget.dataset.slug
                this.selectGuValue = this.selectTarget.dataset.selectGu
                this.selectDongValue = this.subselectTarget.dataset.selectDong

                fetch(`/administration_json?region=${this.regionValue}`)
                    .then((response) => { return response.json(); })
                    .then((json) => {
                        var administration = json.administration

                        this.administrationValue = administration
                        var options = Object.keys(administration)
                        var suboption = options[0]
                        for (var i = 0; i < options.length; i = i + 1) {
                            this.addSelectOption(options[i], this.selectTarget);
                            if (this.selectGuValue.length > 0 && this.selectGuValue == options[i]) {
                                suboption = options[i]
                            }
                        }
                        const subSel = this.subselectTarget
                        const suboptions = this.administrationValue[suboption]
                        for (var i = 0; i < suboptions.length; i = i + 1) {
                            this.addSelectOption(suboptions[i], subSel);
                        }

                        if (this.selectGuValue.length > 0) this.selectTarget.value = this.selectGuValue
                        if (this.selectDongValue.length > 0) this.subselectTarget.value = this.selectDongValue
                    });
                this.selectTarget.dataset.action = 'change->custom-form#changeCategory';
            }

        }

    }
    addSelectOption(option, select) {
        const el = document.createElement("option");
        el.href = "#";

        if (option.length > 0) {
            el.value = option;
            el.innerText = option;
        } else {
            el.value = "";
            el.innerText = "선택하세요";
        }
        select.appendChild(el);
    }
    removeAllChild(select) {
        while (select.firstChild) {
            select.removeChild(select.lastChild);
        }
    }
    changeCategory(event) {
        const subSel = this.subselectTarget
        this.removeAllChild(subSel)
        const options = this.administrationValue[this.selectTarget.value]
        for (var i = 0; i < options.length; i = i + 1) {
            this.addSelectOption(options[i], subSel);
        }
    }
}
