import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
export default class extends Controller {
    static targets = ['bordaCandidatePoint' ]
    static values = { meetingId: Number, bordaId: Number, bordaBudget: Number }

    connect(){
    }

    notyMessage(msg, type) {
        new Noty({
            text: msg,
            type: type,
            layout: "topRight",
            theme: "bootstrap-v4",
            progressBar: true,
            timeout: 3000,
        }).show();
    }

    getSiblings(e) {
        // for collecting siblings
        let siblings = [];
        // if no parent, return no sibling
        if(!e.parentNode) {
            return siblings;
        }
        // first child of the parent node
        let sibling  = e.parentNode.firstChild;

        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e) {
                siblings.push(sibling.querySelector('a'));
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };
    total_checked_count(target){
        let count = 0;

        let siblings = this.getSiblings(target.parentNode);
        this.bordaCandidatePointTargets.forEach(function( point, index, points)
        {
            if (!siblings.includes(point))
            {
                if(point.classList.contains('tw-bg-mainPurple')){
                    count += parseInt(point.dataset.point)
                }
            }

        })
        return count;
    }
    check(e){
        if(this.bordaBudgetValue === 0) return;
        if(e.target.classList.contains('tw-bg-mainPurple')) return;

        let total_count = this.total_checked_count(e.target);
        let target_value = parseInt(e.target.dataset.point);

        if(this.bordaBudgetValue < total_count + target_value )
        {
            this.notyMessage(
                document.documentElement.lang === "ko"
                  ? `총합이 ${this.bordaBudgetValue} 보다 넘어갑니다.`
                  : `The total is more than ${this.bordaBudgetValue} points`, "error")
            e.preventDefault();
            e.stopPropagation();
        }

    }
}
