import { Controller } from "@hotwired/stimulus"

import Slideout from "slideout"

export default class extends Controller {
    static targets = ['meeting', 'status', 'stage', 'hamburgerbuttonTangible', 'current_item']
    connect() {
        this.element[this.identifier] = this;

        this.slideoutValue = null;

        this.pageContainerTarget = document.querySelector('.page-container');
        this.hamburgerbuttonTranslateTarget = document.querySelector('.menu-toggle-translate');

        this.openValue = this.meetingTarget.dataset.menuOpen;
        this.hamburgerbuttonTranslateTarget.style.visibility = 'hidden';

        if (this.pageContainerTarget.length <= 0 || this.hasMeetingTarget == false) {
            return;
        }

        $(document).on('menu_controller.state', function(event, callback) {
            callback(this)
        }.bind(this))

        this.layout();

    }
    tangible_button_show(show) {
        if (show) {
            if (document.body.clientWidth < 1280) {
                this.hamburgerbuttonTangibleTarget.style.visibility = 'visible';
            }
            this.hamburgerbuttonTranslateTarget.style.visibility = 'hidden';
        } else {
            this.hamburgerbuttonTangibleTarget.style.visibility = 'hidden';
            if (document.body.clientWidth < 1280) {
                this.hamburgerbuttonTranslateTarget.style.visibility = 'visible';
            }
        }
    }
    menu_not_slide_on_pc() {
        if (document.body.clientWidth > 1280) {
            this.pageContainerTarget.style.transform = 'none';
        }
    }
    layout() {

        if (document.body.clientWidth >= 1280) {
            this.tangible_button_show(false);
            this.pageContainerTarget.classList.remove("page-container-open");
            this.meetingTarget.style.visibility = 'visible';
            this.pageContainerTarget.style.transform = 'none';

            if (this.slideoutValue != null) {
                this.slideoutValue.destroy();
                this.slideoutValue = null;
            }

        } else {
            if (this.slideoutValue == null) {
                this.slideoutValue = new Slideout({
                    panel: this.pageContainerTarget,
                    menu: this.meetingTarget,
                    padding: Math.min(parseInt(getComputedStyle(this.meetingTarget).width, 10), 440),
                    tolerance: 70,
                    side: "right",
                    touch: false,
                });
            }

            this.slideoutValue.on('beforeopen', this.slideout_beforeopen);
            this.slideoutValue.on('open', this.slideout_open);
            this.slideoutValue.on('beforeclose', this.slideout_beforeclose);
            this.slideoutValue.on('close', this.slideout_close);
            this.slideoutValue.on('translate', this.slideout_translate);
            this.slideoutValue.on('translatestart', this.slideout_translatestart);
            this.slideoutValue.on('translateend', this.slideout_translateend);
            this.tangible_button_show(true);
            this.pageContainerTarget.style.width = 'auto';
            this.slideoutValue.on('open', this.slideout_open);
            if (this.slideoutValue.isOpen() == true) {
                this.menuOpen();
            } else {
                this.menuClose();
            }
        }
    }
    resizeWindow(event) {
        this.layout();
    }
    slideout_translate = (translated) => {

    }
    slideout_translatestart = () => {
        this.beforeclose_style();
    }
    slideout_translateend = () => {
        this.close_style();
    }
    slideout_beforeclose = () => {
        this.beforeclose_style();
    }
    slideout_close = () => {
        this.close_style();
    }
    slideout_beforeopen = () => {
        this.beforeopen_style();
    }
    slideout_open = () => {
        this.open_style();
    }

    reset_toggle_translate_position() {
        this.hamburgerbuttonTranslateTarget.style.top = `${parseInt(getComputedStyle(this.hamburgerbuttonTangibleTarget).top, 10)}px`
        this.hamburgerbuttonTranslateTarget.style.left = `${document.body.clientWidth - parseInt(getComputedStyle(this.hamburgerbuttonTangibleTarget).width, 10) + 10}px`
    }
    disconnect() {}

    beforeopen() {
        fetch($(this.statusTarget).data('url'))
            .then(response => response.body)
            .then(stream => {
                return new Response(stream, { headers: { "Content-Type": "text/html" } }).text();
            })
            .then(result => {
                var current_item = $(this.statusTarget).find('.item--current')
                this.statusTarget.innerHTML = $(result).find('#js-meeting-status').html();
                $(this.statusTarget).find("[data-value='" + current_item.data('value') + "']").addClass('item--current')
                if (document.body.clientWidth < 1280) {
                    this.slideoutValue.open();
                }
                this.menu_not_slide_on_pc();
            });
    }
    beforeopen_style() {

        this.tangible_button_show(false);
        this.reset_toggle_translate_position();

        if (document.body.clientWidth < 1280) {
            this.pageContainerTarget.classList.add("page-container-open");
        }
        this.menu_not_slide_on_pc();
        $(".js-confirmation-vote-panel").trigger(
            "townhall-hide-confirmation-vote-panel"
        );
    }
    open_style() {
        this.tangible_button_show(true);
        this.hamburgerbuttonTangibleTarget.style.right = `${parseInt(getComputedStyle(this.meetingTarget).width, 10)}px`

        this.menu_not_slide_on_pc();

    }
    beforeclose_style() {
        this.tangible_button_show(false);
        this.pageContainerTarget.classList.remove("page-container-open");
    }

    close_style() {
        this.hamburgerbuttonTangibleTarget.style.right = '0px'
        this.tangible_button_show(true);
    }
    menuOpen() {
        this.beforeopen();
    }
    menuClose() {
        this.slideoutValue.close();
    }
    menuOpenClose(event) {
        if (this.slideoutValue === null) {
            return false;
        }
        if (this.slideoutValue.isOpen()) {
            this.menuClose()
        } else {
            this.menuOpen();
        }
        return true;
    }

}
