import { Controller } from '@hotwired/stimulus'
import { Swiper, Navigation, Pagination, SwiperOptions, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.min.css';

export default class extends Controller {
    static values = { options: Object, initialSlide: Number, quizType: String }
    static targets = [ 'results', 'ranks', 'finalRank', 'questions', 'answers' ]

    connect() {
        this.swiper = new Swiper(this.element, {
            modules: [Navigation, Pagination, Autoplay],
            ...this.optionsValue,
            initialSlide: this.initialSlideValue,
        })
    }
    goto_next(event){
        if (event.params.counts === event.params.current)
        {
            const resultController = this.application.getControllerForElementAndIdentifier(this.resultsTarget, 'quiz-result')
            resultController.fetch_results();
        }
        this.swiper.slideNext();
    }
    start(){
        this.questionsTargets.forEach( questionTarget => {
            const questionController = this.application.getControllerForElementAndIdentifier(questionTarget, 'question-user');
            questionController.init();
        })
        this.swiper.slideTo(0);
    }
    retest(event){
        if (this.swiper === null) return;
        fetch(`/quizzes/${event.params.id}/reset_answers?user=${event.params.user}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
            this.start();
        })
    }

    show_result(event){
        if (this.swiper === null) return;
        fetch(`/quizzes/${event.params.id}/show_results?user=${event.params.user}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
            this.questionsTargets.forEach( questionTarget => {
                const questionController = this.application.getControllerForElementAndIdentifier(questionTarget, 'question-user');
                questionController.show_result();
            })
            this.swiper.slideTo(0);
         })

    }
    disconnect() {
        this.swiper.destroy()
        this.swiper = undefined
    }

    find_slide(question_id, action)
    {
        let slideIndex = 0;
        this.swiper.slides.some( (slide, index) => {
            if (parseInt(slide.dataset.questionId) === question_id && slide.dataset.action === action )
            {
                slideIndex = index;
            }
        })

        return slideIndex;
    }

    perform(action, question_id)
    {
        if (action === 'start'){ return;}
        else if (action === 'start') {
            this.start();
        }
        else if (action === 'final_rank') {
            this.swiper.slideTo(this.swiper.slides.length - 1);
            const resultController = this.application.getControllerForElementAndIdentifier(this.finalRankTarget, 'quiz-final-rank')
            resultController.fetch_results();
        }
        else if (action === 'close') {
            this.questionsTargets.some( questionTarget => {
                const questionController = this.application.getControllerForElementAndIdentifier(questionTarget, 'question-user');
                if(questionController.questionIdValue === question_id){
                    questionController.show_result();
                }
            })
        }
        else{
            let slide = this.find_slide(question_id, action);
            if (slide === 0) {
                return;
            }
            if (action === 'rank'){
                const rankController = this.application.getControllerForElementAndIdentifier(this.ranksTargets[this.swiper.slides[this.swiper.realIndex].dataset.seq-1], 'question-rank');
                rankController.fetch_result();
            }
            this.swiper.slideTo(slide);
        }
    }

    get defaultOptions() {
        return {}
    }
}

