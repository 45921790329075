import { Controller } from "@hotwired/stimulus"

import { Menu } from './menu_controller'

export default class extends Controller {

    static values = { url: String }

    connect() {}

    menuOpenClose(event) {
        var ret = false;
        $(document).trigger('menu_controller.state', function(menuController) {
            ret = menuController.menuOpenClose(event);

        })
        if (ret == false && this.hasUrlValue) {
            window.location.href = this.urlValue;

        }
    }

}
