import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = ['topbanner', 'navbarMobile', 'navbar', 'mainpage']
    connect() {
        this.layout();
    }

    resizeWindow(evt) {
        this.layout();
    }

    layout() {
        if (this.hasTopbannerTarget) {
            if (this.topbannerTarget.clientWidth == 0) {
                this.navbarTarget.style.paddingTop = ``;
                this.mainpageTarget.style.paddingTop = ``;
                return;
            }
            if (window.innerWidth >= 768){
              if (this.navbarTarget.style.paddingTop.length == 0)
                  this.navbarTarget.style.paddingTop = `${this.topbannerTarget.offsetHeight}px`
                  if (window.innerWidth > 1200){
                      this.mainpageTarget.style.paddingTop = `${this.topbannerTarget.offsetHeight + this.navbarTarget.clientHeight - 10}px`
                  }else{
                      this.mainpageTarget.style.paddingTop = `${this.topbannerTarget.offsetHeight + 60}px`
                  }
            }else{
              if (this.navbarMobileTarget.style.paddingTop.length == 0)
                  this.navbarMobileTarget.style.paddingTop = `${this.topbannerTarget.offsetHeight}px`
                  this.mainpageTarget.style.paddingTop = `${this.topbannerTarget.offsetHeight + 60}px`
            }
        }
    }
}
