import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static values = {
        rateplan: Boolean,
        judge: Boolean,
        ready: Boolean,
        startable: Boolean,
    }

    connect() {
    }
    warn(evt) {
        if(this.rateplan === false) return;
        if(this.readyValue === false) return;
        if(this.judgeValue){
            if(this.startableValue === false){
                alert(
                    document.documentElement.lang === "ko" ?
                    '심사위원에 아무도 할당하지 않아 가중치 투표를 시작할 수 없습니다. 심사위원을 등록해주세요.':
                    'Weighted voting cannot begin because no one has been assigned to the judges. Please register as a judge.'
                );
                evt.preventDefault();
                evt.stopPropagation();
                return;
            }
            if(!confirm(
                document.documentElement.lang === "ko" ?
                "가중치 투표를 한번 시작하면 심사위원 그룹을 수정할 수 없습니다. 시작하시겠습니까?" :
                "Once weighted voting begins, the judge group cannot be modified. Would you like to start?"
                )) {
                evt.preventDefault();
                evt.stopPropagation();
                return;
            }
        }
    }
}
