import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';

export default class extends Controller {
    static values = { meetingId: Number,
        quizId: Number, questionId: Number, type: String}
    static targets = [ 'answerNumber', 'hbar', 'option', 'optionTitle', 'correct', 'seq']
    connect(){
    }
    init(){
        this.optionTargets.forEach( option => {
            if(option.dataset.right === 'true' )
            {
                option.classList.remove("tw-border-4");
                option.classList.remove("tw-border-green-400");
            }
            else{
                option.classList.add("tw-from-cyan-100")
                option.classList.add("tw-to-green-50")
                option.classList.remove("tw-bg-gray-200")
            }
        })
        this.correctTargets.forEach( correct => {
            correct.classList.add("tw-hidden")
            if(correct.dataset.right === 'true'){
                correct.innerText = 'O'
                correct.classList.remove("tw-text-green-600")
            }
            else{
                correct.innerText = 'X'
                correct.classList.remove("tw-text-red-600")
            }
        })
        this.seqTargets.forEach( seq => {
            if(seq.dataset.right === 'false' ){
                seq.classList.replace("tw-text-gray-500",seq.dataset.color)
            }
        })
        this.optionTitleTargets.forEach( title => {
            if(title.dataset.right === 'false' ){
                title.classList.replace("tw-text-gray-500", title.dataset.color)
            }
        })
        this.answerNumberTargets.forEach( (answerNumber) =>{
            answerNumber.innerText = '';
        })
        if(this.hasHbarTarget){
            this.hbarTargets.forEach( (hbar) => {
                hbar.style.width = `0px`
                hbar.classList.replace("tw-bg-gray-400", "tw-bg-gradient-to-b");
            })
        }
    }
    fetch_answers(){
        this.init();
        fetch(`/quizzes/${this.quizIdValue}/answer_counts?question=${this.questionIdValue}`).then((response) => {
            return response.json();
          })
          .then((json) => {
            json.options.forEach((option, index) => {
                this.answerNumberTargets[index].innerText = option
                if(this.answerNumberTargets[index].innerText === '0'){
                    this.answerNumberTargets[index].innerText = ''
                }
            })
            if(this.hasHbarTarget){
                json.answer_ratio.forEach( (width, index) => {
                    this.hbarTargets[index].style.width = `${width}%`
                })
            }
        })

    }
    answers(payload){
        payload.answer_counts.forEach( (answer_count, index) => {
            this.answerNumberTargets[index].innerText = answer_count[1]
            if(this.answerNumberTargets[index].innerText === '0'){
                this.answerNumberTargets[index].innerText = ''
            }
        })
        if(payload.answer_ratio === 0) {
            return;
        }
        if(this.hasHbarTarget){
            payload.answer_ratio.forEach( (width, index) => {
                this.hbarTargets[index].style.width = `${width}%`
            })
        }
    }
    open(){
        this.init();
        this.optionTargets.forEach( option => {
            option.classList.remove("tw-border-4");
            option.classList.remove("tw-border-green-400");
        })
    }
    close(){

        this.optionTargets.forEach( option => {
            if(option.dataset.right === 'true' )
            {
                option.classList.add("tw-border-4");
                option.classList.add("tw-border-green-400");
            }
            else{
                option.classList.remove("tw-from-cyan-100")
                option.classList.remove("tw-to-green-50")
                option.classList.add("tw-bg-gray-200")
            }
        })
        this.answerNumberTargets.forEach( answerNumber => {
            if(answerNumber.dataset.right === 'false' )
            {
                answerNumber.classList.replace("tw-text-green-600", "tw-text-gray-500")
            }
        })

        this.hbarTargets.forEach( h => {
            if(h.dataset.right === 'false' ){
                h.classList.replace("tw-bg-gradient-to-b", "tw-bg-gray-400")
            }
        })
        this.optionTitleTargets.forEach( title => {
            if(title.dataset.right === 'false' ){
                title.classList.replace(title.dataset.color, "tw-text-gray-500")
            }
        })
        this.seqTargets.forEach( seq => {
            if(seq.dataset.right === 'false' ){
                seq.classList.replace(seq.dataset.color, "tw-text-gray-500")
            }
        })
    }
}
