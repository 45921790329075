import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';
import JSConfetti from 'js-confetti'


export default class extends Controller {
    static values = { meetingId: Number,
        quizId: Number}
    static targets = [ 'total', 'attend', 'ratio', 'userName', 'userImage', 'userPoint', 'rank' ]
    connect(){
        this.jsConfetti = new JSConfetti()
        this.confettiTimer = null;
        this.countConffeti = 10;
    }
    stopTimer(){
        clearInterval(this.confettiTimer);
        this.confettiTimer = null;
    }
    init(){
        this.totalTarget.textContent = 0
        this.attendTarget.textContent = 0
        this.ratioTarget.textContent = 0
        this.rankTargets.forEach( (rank) =>{
            if( !rank.classList.contains('tw-hidden')){
                rank.classList.add('tw-hidden');
            }
        })
        this.userNameTargets.forEach( userName => {
            userName.textContent = ''
        })
        this.userPointTargets.forEach( userTime => {
            userTime.textContent =
                document.documentElement.lang === 'ko'
                ? '총 0점'
                : 'Total 0'
        })
        this.userImageTargets.forEach( userImage => {
            userImage.style.backgroundImage = ''
        })
    }
    fetchResult(){
        this.init();
        fetch(`/quizzes/${this.quizIdValue}/final_rank`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
            this.totalTarget.textContent = json.total
            this.attendTarget.textContent = json.attend
            this.ratioTarget.textContent = json.ratio

            json.users.forEach( (user, index) => {
                this.userNameTargets[index].textContent = user[0]
                this.userPointTargets[index].textContent =
                    document.documentElement.lang === 'ko'
                    ? `총 ${user[2]}점`
                    : `Total ${user[2]}`

                this.userImageTargets[index].style.backgroundImage = `url(${user[1]})`
                this.rankTargets[index].classList.remove('tw-hidden')
            })
            if(json.users.length > 0){
                this.confettiTimer = setInterval(() => this.updateInterval(), 1500);
                this.jsConfetti.addConfetti({
                    //emojis: ["🎉", "🥳", "👏", "⚡", "🎈"],
                    emojiSize: 20,
                    confettiRadius: 6,
                    confettiNumber: 300,
                    confettiColors: [
                      "#ff0a54",
                      "#ff477e",
                      "#ff7096",
                      "#ff85a1",
                      "#fbb1bd",
                      "#f9bec7",
                    ],
                  })
            }
        })
    }
    updateInterval(){
        this.countConffeti -= 1;
        this.jsConfetti.addConfetti({
            // emojis: ["🎉", "🥳", "👏", "⚡", "🎈"],
            emojiSize: 20,
            confettiRadius: 6,
            confettiNumber: 300,
            confettiColors: [
              "#ff0a54",
              "#ff477e",
              "#ff7096",
              "#ff85a1",
              "#fbb1bd",
              "#f9bec7",
            ],
          })

        if (this.countConffeti <= 0){
            clearInterval(this.confettiTimer);
            this.confettiTimer = null;
        }
    }
}
