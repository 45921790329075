import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
export default class extends Controller {
    static values = { meetingId: Number,
        quizId: Number}

    static targets = [ 'questions' ]
    connect(){
        this.quizDashboardChannel = new Channel('QuizDashboardChannel', this, { meeting_id: this.meetingIdValue,
            quiz_id: this.quizIdValue });


    }
    perform(action, question_id){
        this.quizDashboardChannel.perform( action,  { meetingId: this.meetingIdValue,
            quiz_id: this.quizIdValue, question_id: question_id });
    }
    answers(payload){
        if(this.hasQuestionsTarget){
            const controller = this.application.getControllerForElementAndIdentifier(
                this.questionsTargets[payload.question_seq-1], 'question-dashboard');
            controller.answers(payload.payload);
        }
    }
    fatal(payload) {
        console.log("fatal");
        console.log(payload);
        location.href = "/";
      }
      error(payload) {
        new Noty({
          text: '뭔가 잘못되었습니다',
          type: "error",
          theme: "bootstrap-v4",
          layout: "topRight",
          timeout: 3000,
        }).show();
      }
      reload(payload) {
        window.location.reload();
      }
}
