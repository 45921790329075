import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
  }
  getToggler(target){
    if(target.getAttribute('data-target')){
      return target;
    }else{
      return this.getToggler(target.parentNode);
    }
  }

  toggle(e) {
    var toggler = this.getToggler(e.target);
    var content = this.findContent(toggler);

    if(content.classList.contains('tw-hidden')){
      this.show(content);
    }else{
      this.hide(content);
    }
  }
  show(content){
      content.classList.remove('tw-hidden');
  }
  hide(content){
      content.classList.add('tw-hidden');
  }

  findContent(toggler){
    let id = toggler.getAttribute('data-target');
    return this.scope.findElement(`[data-accordion-id="${id}"]`);
  }
}
