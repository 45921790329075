import { Controller } from '@hotwired/stimulus'

// Import TinyMCE
import 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
// import contentStyle from '!!raw-loader!tinymce/skins/ui/oxide/content.css';
// import contentStyle2 from '!!raw-loader!tinymce/skins/content/default/content.css';

export default class extends Controller {
  static targets = ['field', 'readmeField'];

  connect() {
    if (this.hasFieldTarget == false) return;
    let contentStyleSheet = this.fieldTarget.dataset.contentStyleSheet;
    console.log(contentStyleSheet);
    console.log(this);

    if (!contentStyleSheet) {
      contentStyleSheet = '/tinymce/skins/content/default/content.css';
    }
    console.log(tinymce);
    const blockFormats = this.hasReadmeFieldTarget
      ? document.documentElement.lang === 'ko'
        ? '제목 1=h1; 제목 2=h2; 기본=p; 작은=h6;'
        : 'Header 1=h1; Header 2=h2; Header 3=h3 P=p; Tiny=h6;'
      : null;

    tinymce.init({
      target: this.fieldTarget,
      height: 500,
      branding: false,
      menubar: false,
      language_url: '/tinymce/langs/ko_KR.js',
      language: 'ko_KR',
      valid_elements: '*[*]',
      content_css: contentStyleSheet,
      block_formats: blockFormats,
      skin_url: '/tinymce/skins/ui/oxide',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen code media',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | ' +
        'bold italic backcolor | ' +
        'alignleft aligncenter  alignright alignjustify | ' +
        'bullist numlist outdent indent | ' +
        'link image media blockquote | ' +
        'table tableinsertdialog tablecellprops tableprops | ' +
        'removeformat',
      automatic_uploads: true,
      convert_urls: false,
      images_upload_url: '/editor_images/upload',
      block_unsupported_drop: false,
      file_picker_types: 'image media',
      images_upload_credentials: true,
      target_list: [
        { title: '현재 페이지', value: '' },
        { title: '새 창', value: '_blank' },
      ],
      link_class_list: [
        { title: '기본', value: '' },
        { title: '버튼', value: 'btn btn-primary' },
      ],
    });
  }
}
