import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel';

export default class extends Controller {
  static targets = ["modal"];
  static values = { meetingId: String,
                    meetingStatus: Boolean,
                    stageItemType: String,
                    stageItemId: String }
    connect() {
      this.meetingChannel = new Channel('StageItemStatusChannel', this, { meeting_id: this.meetingIdValue,
        stage_item_type: this.stageItemTypeValue,
        stage_item_id: this.stageItemIdValue });
      this.modal_controller();
    }
    modal_controller(){
      this.modalController = this.application.getControllerForElementAndIdentifier(
        this.modalTarget,
        "modal"
    );
    }
    update_item_status(payload){

      if (this.meetingStatusValue === false) return;
      if (payload.stage_item_id !== parseInt(this.stageItemIdValue) || payload.stage_item_type !== this.stageItemTypeValue) {
          return;
      }
      this.modal_controller();

      if (!payload.need_to_reload) return;

      if (payload.target_url) {
        window.location.href = payload.target_url;
      } else {
          window.location.reload();
      }
    }


}
