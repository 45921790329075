import { Controller } from "@hotwired/stimulus"
import urlParser from 'js-video-url-parser/lib/base'
import 'js-video-url-parser/lib/provider/youtube'

export default class extends Controller {
  connect() {
    let iframeTags = this.element.getElementsByTagName('iframe')

    Array.from(iframeTags).forEach((iframeTag) => {
      let videoInfo
      let srcUrl = iframeTag.getAttribute('src')
      if (srcUrl) {
        videoInfo = urlParser.parse(srcUrl)
      }

      if (videoInfo) {
        let embedVideoUrl = urlParser.create({ videoInfo, format: 'embed' })
        iframeTag.outerHTML = `
          <div class="video-container">
            <iframe src="${embedVideoUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>`
      } else {
        iframeTag.setAttribute('target', '_blank')
      }
    })
  }
}
