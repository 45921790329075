import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import Channel from "../channels/channel";

import { fetchResponseCheck } from "../libs/fetch_check_response";

import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";

export default class extends Controller {
  static targets = ["templateOx", "templateOne", "templateMulti", "itemContainer", "item"];
  static values = { item: String, locale: String };

  connect() {
    this.sortable = Sortable.create(this.itemContainerTarget, {
      animation: 150,
      onEnd: this.resetSequence.bind(this),
      handle: '.cursor-move'
    });
    this.resetSequence();
  }
  notyMessage(msg, type) {
    new Noty({
      text: msg,
      type: type,
      layout: "topCenter",
      theme: "bootstrap-v4",
      progressBar: true,
      timeout: 3000,
    }).show();
  }

  addItem(event) {
    event.preventDefault();

    var content = null;

    if(event.params.shape === 'ox'){
      content = this.templateOxTarget.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime()
      );
    }
    if(event.params.shape === 'one'){
      content = this.templateOneTarget.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime()
      );
    }
    if(event.params.shape === 'multi'){
      content = this.templateMultiTarget.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime()
      );
    }
    this.itemContainerTarget.insertAdjacentHTML("beforeend", content);
    this.resetSequence();
  }

  removeItem(event) {
    event.preventDefault();

    if (
      this.itemContainerTarget.querySelectorAll(".question").length -
        this.itemContainerTarget.querySelectorAll("tr[style='display: none;'").length ==
      1
    ) {
      this.notyMessage(
        document.documentElement.lang === 'ko'
        ? "문제가 하나라도 있어야 합니다"
        : "there must be at least one problem", "error");
      return;
    }

    let itemTarget = event.target.closest(
      "[data-quiz-nested-form-target='item']"
    );

    itemTarget.querySelector("input[name*='_destroy']").value = 1;
    itemTarget.style.display = "none";

    this.resetSequence();
  }

  adjust(event) {
    this.resetSequence();
  }

  resetSequence() {
    let seq = 1;
    this.itemTargets.forEach((itemTarget) => {
      let hiddenField = itemTarget.querySelector("input[name*='_destroy']");
      let seqField = itemTarget.querySelector(
        "[data-quiz-nested-form-target='seq']"
      );
      if (seqField) {
        if (!hiddenField || hiddenField.value != "1") {
          seqField.value = seq;
          seq++;
        }
      }
    });
  }
}
