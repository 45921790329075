import { Controller } from "@hotwired/stimulus"
import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";

export default class extends Controller {
  static targets = ['view', 'clear', 'input']
  static values = {
    width: Number, height: Number
  };
    connect() {
      this.isRun = false
      this.clearTarget.classList.add('tw-hidden');

    }
    notyMessage(msg, type) {
      new Noty({
        text: msg,
        type: type,
        layout: "topCenter",
        theme: "bootstrap-v4",
        progressBar: true,
        timeout: 3000,
      }).show();
    }

    clearImage(event) {
      // Blob URL 해제
      if (this.viewTarget.src) {
        URL.revokeObjectURL(this.viewTarget.src);
      }

      // 이미지 뷰 초기화
      this.viewTarget.src = '';

      this.inputTarget.dataset.commentImageId = '';

      // clear 버튼 숨김
      this.clearTarget.classList.add('tw-hidden');

      // 입력 필드 초기화
      // 'input' 타겟이 필요합니다. 예를 들면 data-image-uploader-target="input"
      if (this.hasInputTarget) {
        this.inputTarget.value = null;
      }

      event.preventDefault();
      event.stopPropagation();
    }

    preview(event){
      event.preventDefault();
      let url = ""
      if(event.target.files[0].size > 5242880){
        this.notyMessage(
          document.documentElement.lang === "ko" ?
            "이미지 파일 크기는 5M이하로 제한합니다"
            : "Image file size is limited to 5M or less",
          "error"
        );
        return;

      }
      if (event.target.files.length > 0){
        url = URL.createObjectURL(event.target.files[0]);

        if (this.hasClearTarget){
          this.clearTarget.classList.remove('tw-hidden');
        }
      }
      this.viewTarget.src = url;
    }

    getMeta(url, cb){
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
     }

    disconnect() {
      this.isRun = false
    }
}
