import { Controller } from "@hotwired/stimulus";
import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";
export default class extends Controller {
  static targets = ["archive", "desc", "thumbnail"];
  connect() {}
  checkvalidate(evt) {
    if (!this.hasArchiveTarget) return;
    if (this.archiveTarget.checked == false) return;

    if (!this.hasDescTarget) return;
    if (!this.hasThumbnailTarget) return;

    if (this.descTarget.value == "") {
      new Noty({
        text:
          document.documentElement.lang === "ko"
            ? "공개를 선택하였으므로, 설명을 넣어주세요."
            : "You have chosen to disclose it, so please provide an explanation.",
        type: "error",
        theme: "bootstrap-v4",
        layout: "center",
        timeout: 3000,
      }).show();
      evt.preventDefault();
      evt.stopPropagation();
    }
    let count = 0;
    this.thumbnailTargets.forEach((thumbnail) => {
      if (thumbnail.value != "") {
        count++;
      }
      if (thumbnail.src != "") {
        count++;
      }
      if ($(thumbnail).is(":checked")) {
        new Noty({
          text:
            document.documentElement.lang === "ko"
              ? "공개를 선택하였으므로, 썸네일 이미지을 넣어주세요."
              : "You have chosen to disclose it, so please put in the thumbnail image.",
          type: "error",
          theme: "bootstrap-v4",
          layout: "center",
          timeout: 3000,
        }).show();
        evt.preventDefault();
        evt.stopPropagation();
        return;
      }
    });
    if (count == 0) {
      new Noty({
        text:
          document.documentElement.lang === "ko"
            ? "공개를 선택하였으므로, 썸네일 이미지을 넣어주세요."
            : "You have chosen to disclose it, so please put in the thumbnail image.",
        type: "error",
        theme: "bootstrap-v4",
        layout: "center",
        timeout: 3000,
      }).show();
      evt.preventDefault();
      evt.stopPropagation();
    }
  }
}
