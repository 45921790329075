import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'

export default class extends Controller {
  static targets = ['currentTimeLeft', 'playIcon', 'pauseIcon']
  connect() {
    this.start()
  }

  disconnect() {
    this.stop()
  }

  countdown() {
    if (this.timeLeft <= 0) {
      this.stop()
      this.timeLeft = 0
      window.location.reload()
    }
    this.currentTimeLeftTarget.textContent = this.timeLeft
    this.timeLeft -= 1
  }

  toggle(event) {
    if (this.timer) {
      this.stop()
    } else {
      this.start()
    }
  }

  start() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(this.countdown.bind(this), 1000)

    this.pauseIconTarget.classList.replace('hide', 'show')
    this.playIconTarget.classList.replace('show', 'hide')
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    this.pauseIconTarget.classList.replace('show', 'hide')
    this.playIconTarget.classList.replace('hide', 'show')
  }

  get timeLeft() {
    return this.data.get('timeLeft')
  }

  set timeLeft(time) {
    this.data.set('timeLeft', time)
  }
}
