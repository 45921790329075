import Dropdown from 'stimulus-dropdown'

export default class extends Dropdown {
    static targets = ['btn']
    static values = { width: Boolean }
  connect() {
    super.connect()
  }
  toggle(event) {
    super.toggle()
    if(this.menuTarget.classList.contains("tw-hidden"))
    {
      this.menuTarget.classList.remove("tw-hidden")
      if(this.hasWidthValue && this.widthValue){
        this.menuTarget.style.width = `${this.btnTarget.clientWidth}px`
      }
    }
    else
        this.menuTarget.classList.add("tw-hidden")
  }

  hide(event) {
    if(!this.hasBtnTarget) return;
    if(event.target.parentElement == this.btnTarget || event.target == this.btnTarget) return;
    if(event.composedPath().includes(this.btnTarget)) return;

    super.hide(event)

    if(!this.menuTarget.classList.contains("tw-hidden"))
        this.menuTarget.classList.add("tw-hidden")
  }
}
