import { Controller } from '@hotwired/stimulus'
import fetch from "node-fetch";

import sourceMapSupport from "source-map-support";
sourceMapSupport.install();
import path from "path";
import fs from "fs";
import html2canvas from 'html2canvas';

export default class extends Controller {
    static values = { title: String }
    connect() {
        this.isRun = false
    }

    disconnect() {
        this.isRun = false
    }

    download(evt) {
        if (this.isRun) {
            return
        }
        evt.title = this.titleValue;
        this.isRun = true
        var body = document.body;
        evt.scale = body.offsetWidth / body.getBoundingClientRect().width;
        document.body.classList.add("screenshot");
        html2canvas(document.body, {
                backgroundColor: null,
                width: body.getBoundingClientRect().width,
                height: body.getBoundingClientRect().height,
                onclone: (el) => {

                    var elTexts = el.querySelectorAll('.count > .digit-con > .digit-x');
                    elTexts.forEach(elText => {
                        elText.style.top = `${elText.offsetTop-25}px`;
                    });
                    var elTexts = el.querySelectorAll('.player-item__count > .digit-con > .digit-x');
                    elTexts.forEach(elText => {
                        elText.style.top = `${elText.offsetTop-50}px`;
                    });

                    var elTexts = el.querySelectorAll('.cheer-desc__count  > .digit-con > .digit-x');
                    elTexts.forEach(elText => {
                        elText.style.top = `${elText.offsetTop-70}px`;
                    });

                    var elTexts = el.querySelectorAll('.battle-option__count');
                    elTexts.forEach(elText => {
                        elText.style.top = `${elText.offsetTop-50}px`;
                    });

                    var favors = el.querySelectorAll('.favor')
                    favors.forEach(favor => {

                        favor.style.backgroundColor = '#ffffff';
                        favor.style.backgroundImage = favor.dataset.imageUrl;
                    });

                    var elTitles = el.querySelectorAll('.choice-title');
                    elTitles.forEach(elTitle => {
                        elTitle.textContent = elTitle.textContent.trim();
                        var range = elTitle.ownerDocument.createRange();
                        range.setStart(elTitle.childNodes[0], 0);
                        range.setEnd(elTitle.childNodes[0], 1);
                        var oriRect = elTitle.getBoundingClientRect();
                        var rangeRect = range.getBoundingClientRect();
                        var style = getComputedStyle(elTitle);
                        var fontSize = parseInt(style.fontSize, 10);
                        var lineHeight = parseInt(style.lineHeight, 10);
                        var oriTextTop = oriRect.top + (lineHeight - fontSize) / 2;
                        var rangeTextTop = rangeRect.bottom - fontSize;

                        elTitle.style.top = `${rangeTextTop}px`
                        elTitle.style.lineHeight = `${lineHeight+10}px`;
                    })
                }
            })
            .then(function(canvas) {

                var a = document.createElement('a');
                a.href = canvas.toDataURL('image/png').replace("image/jpeg", "image/octet-stream");
                a.download = `dashboard-${evt.title}.jpg`;
                a.click();

            })
            .finally(() => {
                this.isRun = false;
                document.body.classList.remove("screenshot");
            });

    }
}
